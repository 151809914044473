import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Avatar,
  Badge,
  IconButton,
  MenuItem,
  FormControl,
  Popover,
  Select,
  Box
} from '@mui/material';
import StyledButton from '../components/StyledButton';
import ToggleMetricsView from '../components/ToggleMetricsView';
import AddIcon from '@mui/icons-material/Add';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import CreateIntegrationDialog from './CreateIntegrationDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsDatagrid from './datagrid/NotificationsDatagrid';
import CreateSupplierDialog from './CreateSupplierDialog';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import StyledIconButton from '../components/StyledIconButton';
import trinity_logo from '../assets/logo.png';

const SelectDropdown = ({ selectedValue, setSelectedValue, menuItems }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ml: '1rem',
        width: '175px',
      }}
      size='small'
    >
      <Select
        labelId="select-small-label"
        id="select-small"
        value={selectedValue}
        onChange={handleChange}
        IconComponent={() => <ArrowDropDownIcon style={{ color: 'white' }} />}
        sx={{
          color: 'white',
          border: '1px solid grey',
          height: '2.25rem',
          borderRadius: '10px',
          '&:focus': {
            borderColor: 'white',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


const TopBar = ({
  logo,
  logoBlob,
  currentView,
  setCurrentView,
  notifications,
  hqIntegration,
  integrations,
  materialsRows,
  productsRows,
  setIntegrations,
  integrationTypes,
  showRoutes,
  setShowRoutes,
  selectedDatagrid,
  setSelectedDatagrid,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  signOut
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };


  const datagridItems = [
    { value: 'Integrations', label: 'Partners' },
    { value: 'Documents', label: 'Documents' },
    { value: 'Recipes', label: 'Recipes' },
    { value: 'Orders', label: 'Purchase Orders' },
    { value: 'Products Inventory', label: 'Products' },
    { value: 'Materials Inventory', label: 'Materials' },
  ];


  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };


  return (
    <>
      <Grid container
        sx={{
          bgcolor: 'black',
          py: '0.3rem',
          px: '1rem',
          borderRadius: '15px',
          border: '1px solid white'
        }}>
        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <img src={trinity_logo} alt="Trinity Logo" style={{ width: '95px' }} />
          <ToggleMetricsView currentView={currentView} setCurrentView={setCurrentView} />
          {currentView !== 'settings' && (
            <>
              <StyledButton
                text="Add Partner"
                variant="outlined"
                width='175px'
                sx={{ ml: '1rem' }}
                icon={<AddIcon />}
                onClick={handleClick}
              />
              {['map', 'dashboard'].includes(currentView) ? (
                null
              ) : (
                <SelectDropdown selectedValue={selectedDatagrid} setSelectedValue={setSelectedDatagrid} menuItems={datagridItems} />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {notifications.length > 0 && (
            <>
              <Badge
                badgeContent={notifications.length}
                sx={{
                  mr: '0.5rem',
                  mt: '5px',
                  '& .MuiBadge-badge': {
                    fontSize: '0.7rem',
                    height: '20px',
                    minWidth: '20px',
                    padding: '2px',
                  }
                }}
                color="secondary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <IconButton
                  style={{ width: '20px', height: '20px' }}
                  onClick={handleNotificationClick}
                >
                  <NotificationsTwoToneIcon color="error" style={{ fontSize: '32px' }} />
                </IconButton>
              </Badge>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleNotificationClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{ width: '55vw', minHeight: '25vh', maxHeight: '60vh', overflow: 'auto' }}>
                  <NotificationsDatagrid
                    logoBlob={logoBlob}
                    rows={notifications}
                    integrations={integrations}
                    materialsRows={materialsRows}
                    productsRows={productsRows}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarAlert={setSnackbarAlert}
                  />
                </Box>
              </Popover>
            </>
          )}
          {logo ? (
            <Avatar
              sx={{
                bgcolor: 'white',
                width: 'auto',
                height: 'auto',
                maxWidth: '100px',
                maxHeight: '35px',
                borderRadius: '10px',
                ml: '1rem',
                mr: '0.5rem',
                p: '0.5rem',
                objectFit: 'contain'
              }}
              alt="logo"
              src={logo}
            />
          ) : (
            <Typography sx={{ color: 'white', ml: '1rem' }}>
              {localStorage.getItem('user')}
            </Typography>
          )}
          <StyledIconButton text="Settings" icon={<SettingsIcon />} onClick={() => setCurrentView('settings')} />
          <StyledIconButton icon={<LogoutIcon />} onClick={handleLogout} />
        </Grid>
      </Grid>
      {hqIntegration ? (
        <CreateIntegrationDialog
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          integrationTypes={integrationTypes.filter(integration => integration.type !== 'HQ')}
          setIntegrations={setIntegrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      ) : (
        <CreateIntegrationDialog
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          integrationTypes={integrationTypes.filter(integration => integration.type === 'HQ')}
          setIntegrations={setIntegrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      <CreateSupplierDialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        itemOptions={materialsRows}
        integrations={integrations.filter(row => row.integration_type.category === "Supplier")}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  );
};

export default TopBar;
