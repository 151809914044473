import React, { useMemo, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';


const PDFViewer = ({ pdfBlob }) => {
  const blobUrl = useMemo(() => URL.createObjectURL(pdfBlob), [pdfBlob]);
  const toolbarPluginInstance = toolbarPlugin();
  const zoomPluginInstance = zoomPlugin();
  const getFilePluginInstance = getFilePlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { Download } = getFilePluginInstance;

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }, [blobUrl]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Toolbar>
        {(props) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ZoomOutButton {...props} />
            <ZoomInButton {...props} />
            <Download {...props} />
          </div>
        )}
      </Toolbar>
      <div style={{ flex: 1, overflow: 'scroll' }}>
        <Viewer
          fileUrl={blobUrl}
          defaultScale={1.0}
          plugins={[toolbarPluginInstance, zoomPluginInstance, getFilePluginInstance]}
        />
      </div>
    </Worker>
  );
};

export default PDFViewer;