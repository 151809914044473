import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import CreateSupplierDialog from '../CreateSupplierDialog';
import CreateInventoryDialog from '../CreateInventoryDialog';
import CreatePODialog from '../CreatePODialog';
import POSender from '../POSender';
import { fetchOrders } from '../../api/orders';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';


export default function NotificationsDatagrid({
  logoBlob,
  rows,
  integrations,
  materialsRows,
  productsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert
}) {
  const columnVisibilityModel = { id: false };
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPODialog, setPOOpenDialog] = useState(false);
  const [openSendDialog, setSendDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedPO, setSelectedPO] = useState('');
  const [orderType, setOrderType] = useState('');
  const [selectedPOsArr, setSelectedPOsArr] = useState('');
  const [orderFromIntegration, setOrderFromIntegration] = useState('');
  const [orderIntegration, setOrderIntegration] = useState('');

  const handleResolveClick = async (row) => {
    if (row.alert_category === "Missing supplier") {
      setOpenSupplierDialog(true);
      setSelectedItemId(row.id);
    } else if (row.alert_category === "Missing manufacturer") {
      setOpenDialog(true);
      setSelectedItemId(row.id);
    } else if (row.alert_category === "Recommended Material PO") {
      const selectedIntegration = integrations.find(integration => integration.id === row.integration_id);
      setOrderType('Supplier');
      setOrderIntegration(selectedIntegration);
      setPOOpenDialog(true);
    } else if (row.alert_category === "Recommended Product PO") {
      const selectedIntegration = integrations.find(integration => integration.id === row.integration_id);
      setOrderType('Manufacturer');
      setOrderIntegration(selectedIntegration);
      setPOOpenDialog(true);
    } else {
      const selectedIntegration = integrations.find(integration => integration.id === row.integration_id);
      const selectedOrderType = selectedIntegration?.integration_type.category === 'Supplier' ? 'material' : 'product'
      setOrderType(selectedOrderType);
      setSelectedPO(row);
      const po_data = await fetchOrders(row.integration_id, selectedOrderType, null, row.alert_category);
      setOrderFromIntegration(selectedIntegration);
      setOrderIntegration(integrations.find(integration => integration.id === po_data[0]?.integration_id_to) || null);
      setSelectedPOsArr(po_data)
      setSendDialogOpen(true);
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'super-app-theme--header' },
    { field: 'alert_category', headerName: 'Category', flex: 0.5, headerClassName: 'super-app-theme--header' },
    { field: 'alert_detail', headerName: 'Details', flex: 1.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.25,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <>
            {['Missing supplier', 'Missing manufacturer'].includes(params.row.alert_category) ? (
              <Tooltip title="Add" id={`${params.row.alert_detail}-view`}>
                <IconButton
                  onClick={() => handleResolveClick(params.row)}
                >
                  <AddIcon sx={{ color: 'black' }} />
                </IconButton>
              </Tooltip>
            ) : ['Recommended Product PO', 'Recommended Material PO'].includes(params.row.alert_category) ? (
              <Tooltip title="Create Draft PO" id={`${params.row.alert_detail}-view`}>
                <IconButton
                  onClick={() => handleResolveClick(params.row)}
                >
                  <CreateOutlinedIcon sx={{ color: 'black' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Send Drafted PO" id={`${params.row.alert_detail}-view`}>
                <IconButton
                  onClick={() => handleResolveClick(params.row)}
                >
                  <EmailOutlinedIcon sx={{ color: 'black' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    }
  ];


  return (
    <>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          columnVisibilityModel={columnVisibilityModel}
          density='compact'
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-row': {
              color: 'black',
            },
          }}
        />
      </Box>
      <CreateInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        rows={rows}
        setRows={null}
        itemOptions={productsRows.filter(row => row.id === selectedItemId)}
        integrations={integrations.filter(row => row.integration_type.category === "Manufacturer")}
        type={'product'}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        inputSelectedItem={productsRows.find(row => row.id === selectedItemId)?.id}
      />
      <CreateSupplierDialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        itemOptions={materialsRows.filter(row => row.id === selectedItemId)}
        integrations={integrations.filter(row => row.integration_type.category === "Supplier")}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        inputSelectedItem={materialsRows.find(row => row.id === selectedItemId)?.id}
      />
      <CreatePODialog
        open={openPODialog}
        onClose={() => setPOOpenDialog(false)}
        setRows={null}
        integrations={integrations}
        productsRows={productsRows}
        materialsRows={materialsRows}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
        inputIntegration={orderIntegration}
      />
      <Dialog open={openSendDialog} onClose={() => setSendDialogOpen(false)} fullWidth maxWidth={'xl'}>
        <DialogTitle>Send PO</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', height: 'auto', maxHeight: '55vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <POSender
              logoBlob={logoBlob}
              selectedPO={selectedPO}
              selectedPOsArr={selectedPOsArr}
              setSelectedPOsArr={setSelectedPOsArr}
              orderType={orderType}
              rows={null}
              setRows={null}
              setTableView={null}
              setSendDialogOpen={setSendDialogOpen}
              integrations={integrations}
              integration={orderFromIntegration}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              inputIntegration={orderIntegration}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}