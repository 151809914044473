import React from 'react';
import { Box, Button, Select, MenuItem, IconButton, Tooltip, Checkbox } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { createProductsInventory, updateProductsInventory, deleteProductsInventory, fetchHistoryInventory } from '../../api/products';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { fetchOrdersTo, updateOrder } from '../../api/orders';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import InventoryChart from '../charts/InventoryChart';

export default function InventoryDialogDatagrid({
  integrationId,
  integrationType,
  is3PL,
  productsRows,
  inventoryRows,
  setInventoryRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null
}) {
  const columnVisibilityModel = {
    id: false,
    product_id: false,
    sales_price: integrationType === 'Manufacturer' && !is3PL ? false : true,
    is_active: integrationType === 'Manufacturer' ? false : true,
    is_turnkey: integrationType === 'Manufacturer' ? true : false,
    po_sent_qty: integrationType === 'Manufacturer' ? false : true,
    in_transit_qty: integrationType === 'Manufacturer' ? false : true,
    sales_per_day: integrationType === 'Manufacturer' ? false : true,
    next_order_date: integrationType === 'Manufacturer' ? false : true,
    safety_stock_qty: integrationType === 'Manufacturer' && !is3PL ? false : true,
    lead_time: integrationType === 'Manufacturer' ? true : false,
  };
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const [inventoryTrendRows, setInventoryTrendRows] = React.useState([]);

  const handleNameChange = async (event, params) => {
    const newId = event.target.value;
    const selectedProduct = productsRows.find(product => product.id === newId);
    const createdInventory = await createProductsInventory(
      {
        integration_id: integrationId,
        product_id: selectedProduct.id,
        qty: null,
        unit_of_measure: null,
        lead_time: null,
        safety_stock_qty: null
      }
    );
    setInventoryRows(prevInventory => prevInventory.filter(row => row.id !== 1).concat(createdInventory));
  };

  const handleDeleteClick = async (id) => {
    if (id !== 1) {
      try {
        const deleteSuccess = await deleteProductsInventory(id);
        console.log(deleteSuccess)
        setInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
        setSnackbarOpen(true);
        setSnackbarMessage("Deleted successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
    else {
      setInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));

    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchOrdersTo(row.integration_id, 'product', 'Delivered', row.product_id);
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchInventoryTrend = async (row) => {
    try {
      const inventoryTrend = await fetchHistoryInventory('product', row.integration_id, row.product_id);
      setInventoryTrendRows(inventoryTrend);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'product_id', headerName: 'ProductID', flex: 1 },
    {
      field: 'name', headerName: 'Name', flex: 1, renderCell: (params) => {
        const selectedProduct = productsRows.find(product => product.id === params.row.product_id);
        const value = selectedProduct ? selectedProduct.name : '';

        return (
          value ? (
            <span>{value}</span>
          ) : (
            <Select
              value={value}
              size='small'
              variant='standard'
              onChange={(event) => handleNameChange(event, params)}
              fullWidth
            >
              {productsRows
                .filter(product => !inventoryRows.some(inventory => inventory.product_id === product.id))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((product) => (
                  <MenuItem key={product.name} value={product.id}>
                    {product.name} [{product.sku}]
                  </MenuItem>
                ))}
            </Select>
          )
        );
      }
    },
    {
      field: 'sku', headerName: 'SKU', flex: 1, renderCell: (params) => {
        const selectedProduct = productsRows.find(product => product.id === params.row.product_id);
        return selectedProduct ? selectedProduct.sku : '';
      }
    },
    { field: 'sales_price', headerName: 'Sales Price ($)', editable: true, flex: 1 },
    {
      field: 'is_active', headerName: 'Active', flex: 0.5, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_active: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'is_turnkey', headerName: 'Turnkey', flex: 0.5, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_turnkey: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'po_sent_qty',
      headerName: 'Expected Inventory',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    {
      field: 'in_transit_qty',
      headerName: 'Inbound Inventory',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    {
      field: 'qty',
      headerName: 'Inventory',
      flex: 1,
      editable: true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    { field: 'safety_stock_qty', headerName: 'Safety Stock Level', flex: 1, editable: true },
    { field: 'lead_time', headerName: 'Lead Time (days)', flex: 1, editable: true },
    {
      field: 'sales_per_day',
      headerName: (
        <Tooltip title="Average sales per day over the last 30 days">
          <span>
            Avg Sales Per Day
            <IconButton size="small" sx={{ ml: 0.25 }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      ),
      flex: 1.2,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        else if (!params.row.sales_per_day_last_week) {
          return <span>{params.value}</span>;
        }
        else if (params.row.sales_per_day_last_week === params.value) {
          return <span>{params.value}</span>;
        }
        const trend_up = params.row.sales_per_day_last_week > params.value;
        return (
          <Tooltip
            title={trend_up ? 'Trending up in last week' : 'Trending down in last week'}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {params.value}
              <IconButton size="small">
                {trend_up ? <TrendingUpIcon sx={{ color: 'green' }} /> : <TrendingDownIcon sx={{ color: 'red' }} />}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'next_order_date',
      headerName: (
        <Tooltip title="Recommended next order date. If this value is blank, there is no manufacturer/lead time listed in Trinity for this product.">
          <span>
            Next Order
            <IconButton size="small" sx={{ ml: 0.25 }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      ),
      flex: 1,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {format(date, 'MM/dd/yy')}
            {params.row.next_order < 7 ? <WarningAmberIcon sx={{ color: 'orange', ml: '0.25rem' }} /> : ''}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {['3PL', 'Distributor'].includes(integrationType) && (
            <>
              <Tooltip title="Inventory Trend">
                <IconButton
                  color="inherit"
                  onClick={() => handleFetchInventoryTrend(params.row)}
                >
                  <TimelineIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {['3PL', 'Distributor'].includes(integrationType) && params.row.delivered_qty > 0 && (
            <>
              <Tooltip title="Product Card">
                <IconButton
                  color="inherit"
                  onClick={() => handleFetchInventoryDetails(params.row)}
                >
                  <ManageSearchIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <IconButton
            color="inherit"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];


  const handleAddInventory = async () => {
    const hasId1 = inventoryRows.some(row => row.id === 1);
    if (!hasId1) {
      const createdInventory = {
        id: 1,
        integration_id: integrationId,
        product_id: null,
        qty: null,
        unit_of_measure: null,
        lead_time: null,
        safety_stock_qty: null
      }
      setInventoryRows(prevInventory => [...prevInventory, createdInventory]);
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.qty >= 0) {
      const updatedRow = { ...newRow, isNew: false };
      try {
        const data = await updateProductsInventory(newRow.id, newRow);
        updatedRow.last_updated = data.last_updated;
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully");
        setSnackbarAlert("success");
        setInventoryRows(inventoryRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
        return null;
      }
    }
  };

  const processOrderRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    try {
      const data = await updateOrder(newRow.id, newRow, 'product');
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setInventoryDetailsRows(inventoryDetailsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      throw error
    }
  };

  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage("Invalid value entered");
    setSnackbarAlert("error");
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddInventory}
        >
          Add Product
        </Button>
      </GridToolbarContainer>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            setInventoryDetailsRows([]);
            setInventoryTrendRows([]);
          }}
        >
          Back To Table
        </Button>
      </GridToolbarContainer>
    );
  };

  const detailsColumns = [
    { field: 'id', headerName: 'ID', width: 80, headerClassName: 'super-app-theme--header' },
    { field: 'name', headerName: 'Material', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lot_number', headerName: 'Lot', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'expiration_date_raw', headerName: 'Expiration Date', editable: true, flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'unit_price', headerName: 'Order Price ($)', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'landed_price',
      headerName: 'Landed Cost ($)',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? params.value.toFixed(2) : ''
    },
    {
      field: 'qty',
      headerName: 'Order Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'invoice_qty',
      headerName: 'Invoice Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header', renderCell: (params) => params.value ? Number(params.value).toLocaleString() : ''
    },
    {
      field: 'est_arrival',
      headerName: 'Arrival Date',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy');
      },
    }
  ];

  const trendColumns = [
    {
      field: 'create_time',
      headerName: 'Time',
      width: 180,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm:ss');
      },
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      flex: 0.75,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : ''
    },
    { field: 'edited_by', headerName: 'Source', flex: 1, headerClassName: 'super-app-theme--header' },
  ]


  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: '50vh' }}>
        {inventoryDetailsRows.length > 0 ? (
          <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh' }}>
            <DataGrid
              rows={inventoryDetailsRows}
              columns={detailsColumns}
              density='compact'
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processOrderRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: CustomDetailsToolbar,
              }}
            />
          </Box>
        ) : inventoryTrendRows.length > 0 ? (
          <>
            <Box sx={{ width: '30%', height: '100%', mr: '0.5rem' }}>
              <DataGrid
                rows={inventoryTrendRows}
                columns={trendColumns}
                density='compact'
                hideFooter
                slots={{
                  toolbar: CustomDetailsToolbar,
                }}
              />
            </Box>
            <Box sx={{ width: '70%', height: '100' }}>
              <InventoryChart data={inventoryTrendRows} />
            </Box>
          </>
        ) : (
          <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh', mr: inventoryDetailsRows.length ? '1rem' : '0' }}>
            <DataGrid
              rows={inventoryRows}
              columns={columns}
              density='compact'
              loading={loading}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: integrationType === 'Manufacturer' ? CustomToolbar : CustomToolbar,
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
