import React, { useState } from 'react';
import { Box, IconButton, Button, MenuItem, Select, Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { createProduct, updateProduct, fetchRecipe, createRecipe, updateRecipe, deleteProduct, deleteRecipe } from '../../api/products';
import { fetchShipments } from '../../api/shipments';
import ShipmentsDatagrid from './ShipmentsDatagrid';

const ProductRecipeTable = ({
  integrationId,
  productsRows,
  setProductsRows,
  materialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  height = '50vh',
  loading = null,
}) => {
  const [recipeRows, setRecipeRows] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [addingRecipeType, setAddingRecipeType] = useState(null);
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);


  const handleDeleteProductClick = async (id) => {
    try {
      const deleteSuccess = await deleteProduct(id);
      console.log(deleteSuccess)
      setProductsRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleDeleteRecipeClick = async (id) => {
    try {
      const deleteSuccess = await deleteRecipe(id);
      console.log(deleteSuccess)
      setRecipeRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, null, row.id);
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const productColumns = [
    { field: 'name', headerName: 'Item', flex: 1, editable: true },
    { field: 'sku', headerName: 'SKU', flex: 1, editable: true },
    { field: 'upc', headerName: 'UPC', flex: 1, editable: true },
    {
      field: 'default_price',
      headerName: 'Default Price ($)',
      width: 200,
      editable: true,
      renderCell: (params) => (
        params.value ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value) : 0
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Product Card">
            <IconButton
              color='inherit'
              onClick={() => handleFetchInventoryDetails(params.row)}
            >
              <ManageSearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Recipe/BOM">
            <IconButton
              color='inherit'
              onClick={() => handleExpandRecipe(params.row.id)}
            >
              <FormatListNumberedIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            color="inherit"
            onClick={() => handleDeleteProductClick(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const handleNameChange = (event, params) => {
    const newId = event.target.value;

    if (addingRecipeType === 'product') {
      const selectedProduct = productsRows.find(product => product.id === newId);
      setRecipeRows((prevRows) =>
        prevRows.map((row) =>
          row.id === params.id ? { ...row, sku: selectedProduct.sku, name: selectedProduct.name, product_id_ref: selectedProduct.id } : row
        )
      );
    } else {
      const selectedMaterial = materialsRows.find(material => material.id === newId);
      setRecipeRows((prevRows) =>
        prevRows.map((row) =>
          row.id === params.id ? { ...row, sku: selectedMaterial.sku, name: selectedMaterial.name, material_id: selectedMaterial.id } : row
        )
      );
    }
  };

  const recipeColumns = [
    {
      field: 'name', headerName: 'Name', flex: 1, renderCell: (params) => {
        let rowsToRender = materialsRows;
        let columnToRender = 'material_id';
        if (addingRecipeType === 'product') {
          rowsToRender = productsRows;
          columnToRender = 'product_id_ref';
        }
        const selectedOption = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        const isMaterial = params.row.material_id !== null;
        const value = selectedOption ? selectedOption.name : '';

        return (
          value ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isMaterial ? <OilBarrelIcon style={{ marginRight: 4 }} /> : <WidgetsIcon style={{ marginRight: 4 }} />}
              {value}
            </div>
          ) : (
            <Select
              value={value}
              size='small'
              variant='standard'
              onChange={(event) => handleNameChange(event, params)}
              fullWidth
            >
              {
                rowsToRender
                  .filter(input => !recipeRows.some(recipe => recipe[columnToRender] === input.id))
                  .map(input => (
                    <MenuItem key={input.id} value={input.id}>
                      {input.name}
                    </MenuItem>
                  ))
              }
            </Select>
          )
        );
      }
    },
    {
      field: 'sku', headerName: 'SKU', flex: 1, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedMaterial ? selectedMaterial.sku : '';
      }
    },
    { field: 'qty', headerName: 'Qty', flex: 0.5, editable: true },
    {
      field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedMaterial ? selectedMaterial.usage_uom : '';
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          color="inherit"
          onClick={() => handleDeleteRecipeClick(params.row.id)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];



  const handleExpandRecipe = async (productId) => {
    setSelectedProductId(productId);
    try {
      const data = await fetchRecipe(productId);
      setRecipeRows(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleAddProduct = async () => {
    try {
      const createdProduct = await createProduct(
        {
          integration_id: integrationId,
          name: 'New Product',
          sku: '',
          upc: null,
        }
      );
      setProductsRows(prevProducts => [...prevProducts, createdProduct]);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleAddRecipe = async (type) => {
    setAddingRecipeType(type)
    try {
      const createdRecipe = await createRecipe(
        {
          product_id: selectedProductId,
          material_id: null,
          product_id_ref: null,
          qty: null,
        }
      );
      setRecipeRows(prevRecipes => [...prevRecipes, createdRecipe]);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const processProductRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateProduct(newRow.id, newRow);
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setProductsRows(productsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error("Error in processProductRowUpdate:", error);
      throw error;
    }
  };

  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage(error.response.data.detail);
    setSnackbarAlert("error");
  };

  const processRecipeRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateRecipe(newRow.id, {
        id: newRow.id,
        material_id: newRow.material_id,
        product_id_ref: newRow.product_id_ref,
        qty: newRow.qty,
      });
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setRecipeRows(recipeRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      throw error
    }
  };

  const ProductsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddProduct}
        >
          Add Product
        </Button>
      </GridToolbarContainer>
    );
  };

  const RecipesToolbar = () => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <GridToolbarContainer>
            <Button
              color="inherit"
              variant='outlined'
              startIcon={<OilBarrelIcon />}
              onClick={() => handleAddRecipe('material')}
            >
              Add Material Input
            </Button>
          </GridToolbarContainer>
          <GridToolbarContainer>
            <Button
              color="inherit"
              variant='outlined'
              startIcon={<WidgetsIcon />}
              onClick={() => handleAddRecipe('product')}
            >
              Add Product Input
            </Button>
          </GridToolbarContainer>
          <GridToolbarContainer>
            <Button
              color="inherit"
              variant='outlined'
              startIcon={<CloseIcon />}
              onClick={() => {
                setRecipeRows([]);
                setSelectedProductId(null)
              }}
            >
              Close
            </Button>
          </GridToolbarContainer>
        </Box>
      </>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setInventoryDetailsRows([])}
        >
          Back to table
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <Box sx={{ display: 'flex', width: '100%', height: { height }, maxHeight: { height } }}>
      {
        inventoryDetailsRows.length === 0 && !selectedProductId ? (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <DataGrid
                rows={productsRows}
                columns={productColumns}
                disableSelectionOnClick
                density="compact"
                loading={loading}
                hideFooter
                processRowUpdate={processProductRowUpdate}
                onProcessRowUpdateError={processRowUpdateError}
                slots={{
                  toolbar: ProductsToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
          </>
        ) : selectedProductId ? (
          <Box sx={{ width: '100%', height: '100%' }}>
            <DataGrid
              rows={recipeRows}
              columns={recipeColumns}
              disableSelectionOnClick
              density="compact"
              hideFooter
              processRowUpdate={processRecipeRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: RecipesToolbar,
              }}
              sx={{
                backgroundColor: 'white',
                '& .MuiDataGrid-row': {
                  color: 'black',
                },
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: '100%', height: '100%' }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
            />
          </Box>
        )}
    </Box>
  );
};

export default ProductRecipeTable;
