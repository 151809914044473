import React, { useState } from 'react';
import { Box, Button, Checkbox, IconButton, Tooltip, TextField } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { createMaterial, updateMaterial, deleteMaterial, fetchRecipeReverse } from '../../api/products';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchShipments } from '../../api/shipments';
import ShipmentsDatagrid from './ShipmentsDatagrid';


export default function MaterialsDialogDatagrid({
  integrationId,
  productsRows,
  materialsRows,
  setMaterialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  height = '50vh',
  marginTop = false,
  loading = null,
}) {
  const [reverseRecipeRows, setReverseRecipeRows] = useState([]);
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const handleAddMaterial = async () => {
    try {
      const createdMaterial = await createMaterial(
        {
          integration_id: integrationId,
          sku: 'New Sku',
          name: 'New Material',
          is_packaging: 'false'
        }
      );
      setMaterialsRows(prevMaterials => [...prevMaterials, createdMaterial]);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleReverseLookupClick = async (id) => {
    try {
      const reverseRecipes = await fetchRecipeReverse(id);
      setReverseRecipeRows(reverseRecipes);
      if (reverseRecipes.length > 0) {
        setColumnVisibilityModel({ actions: false });
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteMaterial(id);
      setMaterialsRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateMaterial(newRow.id, newRow);
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setMaterialsRows(materialsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error('Error updating row:', error);
      return null;
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, null, null, row.id);
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Item', flex: 1, editable: true },
    { field: 'sku', headerName: 'SKU', flex: 1, editable: true },
    {
      field: 'is_packaging',
      headerName: 'Packaging?',
      flex: 0.5,
      editable: true,
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_packaging: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    { field: 'usage_uom', headerName: 'Usage UoM', flex: 0.5, editable: true },
    { field: 'purchasing_uom', headerName: 'Purchase UoM', flex: 0.5, editable: true },
    {
      field: 'conversion',
      headerName: 'Unit Conversion Rate',
      flex: 0.75,
      editable: true,
      renderCell: (params) => {
        const handleChange = (event) => {
          const updatedValue = event.target.value;
          const updatedRow = {
            ...params.row,
            conversion: updatedValue,
          };
          processRowUpdate(updatedRow, params.row);
        };

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            {'1 ' + params.row.purchasing_uom + ' = '}
            <TextField
              size="small"
              variant="outlined"
              value={params.row.conversion}
              onChange={handleChange}
              sx={{
                width: '100px',
                mx: '5px',
                '& .MuiInputBase-root': {
                  height: '30px',
                  fontSize: 'inherit',
                },
              }}
            />
            {' ' + (params.row.usage_uom ? params.row.usage_uom : '')}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Material Card">
            <IconButton
              color='inherit'
              onClick={() => handleFetchInventoryDetails(params.row)}
            >
              <ManageSearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Used in BOMs">
            <IconButton
              color="inherit"
              onClick={() => handleReverseLookupClick(params.row.id)}
            >
              <FormatListNumberedIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            color="inherit"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const recipeColumns = [
    {
      field: 'product_id',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => {
        const productRow = productsRows.find(row => row.id === params.value);
        return productRow ? productRow.name : '';
      }
    },
    {
      field: 'qty',
      headerName: 'Quantity Used',
      flex: 1,
      renderCell: (params) => {
        const materialRow = materialsRows.find(row => row.id === params.row.material_id);
        return materialRow ? `${params.row.qty} ${materialRow.usage_uom}` : '';
      }
    },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddMaterial}
        >
          Add Material
        </Button>
      </GridToolbarContainer>
    );
  };

  const RecipesToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<CloseIcon />}
          onClick={() => {
            setColumnVisibilityModel({ actions: true });
            setReverseRecipeRows([])
          }}
        >
          Close
        </Button>
      </GridToolbarContainer>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setInventoryDetailsRows([])}
        >
          Back to table
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: { height }, maxHeight: { height } }}>
        {inventoryDetailsRows.length === 0 ? (
          <>
            <Box
              sx={{
                width: reverseRecipeRows.length === 0 ? '100%' : '70%',
                height: { height },
                maxHeight: { height },
                mt: marginTop ? '1rem' : 0,
                mr: reverseRecipeRows.length > 0 ? '1rem' : '0'
              }}
            >
              <DataGrid
                rows={materialsRows}
                columns={columns}
                density='compact'
                hideFooter
                loading={loading}
                processRowUpdate={processRowUpdate}
                columnVisibilityModel={columnVisibilityModel}
                slots={{
                  toolbar: CustomToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            {
              reverseRecipeRows.length > 0 && (
                <Box sx={{ width: '29%', height: '100%', mt: marginTop ? '1rem' : 0 }}>
                  <DataGrid
                    rows={reverseRecipeRows}
                    columns={recipeColumns}
                    density='compact'
                    hideFooter
                    slots={{
                      toolbar: RecipesToolbar,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      '& .MuiDataGrid-row': {
                        color: 'black',
                      },
                    }}
                  />
                </Box>
              )
            }
          </>
        ) : (
          <Box sx={{ width: '100%', height: '100%', mt: marginTop ? '1rem' : 0, }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
