import * as React from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Skeleton,
  Tooltip,
  IconButton
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InventoryTrend from '../components/charts/InventoryTrend';
import BasicPieChart from '../components/charts/BasicPieChart';
import InventoryBar from '../components/charts/InventoryBar';
import { fetchTrend } from '../api/forecast';

const SelectDropdown = ({ selectedValue, setSelectedValue, menuItems }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ml: '0.5rem',
        width: '220px',
      }}
      size='small'
    >
      <Select
        labelId="select-small-label"
        id="select-small"
        value={selectedValue}
        onChange={handleChange}
        IconComponent={() => <ArrowDropDownIcon style={{ color: 'white' }} />}
        sx={{
          color: 'white',
          border: '1px solid grey',
          height: '2.25rem',
          borderRadius: '10px',
          '&:focus': {
            borderColor: 'white',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function Trends() {
  const [chartGrouping, setChartGrouping] = React.useState('partner');
  const [isLoading, setIsLoading] = React.useState(false);
  const [chartHistory, setChartHistory] = React.useState('30');
  const [isStacked, setIsStacked] = React.useState(false);
  const [trendRows, setTrendRows] = React.useState(null);
  const [inventoryRows, setInventoryRows] = React.useState(null);
  const [salesRows, setSalesRows] = React.useState(null);
  const [totalSales, setTotalSales] = React.useState('');
  const [totalInventory, setTotalInventory] = React.useState('');
  const [totalSalesDollars, setTotalSalesDollars] = React.useState('');
  const [totalInventoryDollars, setTotalInventoryDollars] = React.useState('');

  React.useEffect(() => {
    const fetchTrendsData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchTrend(chartGrouping, chartHistory);
        setTrendRows(data.inventory_trend);
        setInventoryRows(data.inventory_breakdown);
        setSalesRows(data.sales_breakdown);
        setTotalInventory(data.summary_stats.total_inventory);
        setTotalSales(data.summary_stats.total_sales_per_day);
        setTotalInventoryDollars(data.summary_stats.total_inventory_dollars);
        setTotalSalesDollars(data.summary_stats.total_sales_per_day_dollars);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrendsData();
  }, [chartGrouping, chartHistory]);

  return (
    <>
      <Box sx={{ height: '50%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ height: '100%', width: '25%', py: '2rem', pl: '2rem' }}>
          <Typography variant="h6" color={'white'} sx={{ mb: '1rem' }}>Dashboard Control</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Group by:</Typography>
            <SelectDropdown
              selectedValue={chartGrouping}
              setSelectedValue={setChartGrouping}
              menuItems={[
                { label: 'Partner', value: 'partner' },
                { label: 'Product', value: 'product' },
              ]} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Past:</Typography>
            <SelectDropdown
              selectedValue={chartHistory}
              setSelectedValue={setChartHistory}
              menuItems={[
                { label: 'Week', value: '7' },
                { label: 'Month', value: '30' },
                { label: 'Quarter', value: '90' },
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Show trend as stacked:</Typography>
            <Checkbox
              checked={isStacked}
              onChange={(event) => setIsStacked(event.target.checked)}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{ height: '100%', width: '75%', p: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridTemplateRows: '1fr 1fr', gap: '1rem' }}>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: '1rem' }}>Total Inventory (3PL & Distributor)</Typography>
            {isLoading ? (
              <>
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
              </>
            ) : (
              <>
                <Typography variant="h5" color={'white'} sx={{ mb: '0.5rem' }}>{totalInventory.toLocaleString('en-US')} units / ${totalInventoryDollars.toLocaleString('en-US')}</Typography>
                <Typography variant="h5" color={'white'}>{(totalInventory / totalSales).toFixed(1)} days of inventory</Typography>
              </>
            )}
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Box sx={{ height: '15vh', width: isLoading ? '100%' : '125%' }}>
              <Typography variant="body1" color={'white'} sx={{ mb: isLoading ? '1rem' : '0' }}>Inventory Mix</Typography>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                inventoryRows && inventoryRows.length > 0 && (
                  <BasicPieChart plotData={inventoryRows} />
                )
              )}
            </Box>
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: isLoading ? '1rem' : '0' }}>Inventory Details</Typography>
            <Box sx={{ height: '15vh', width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                inventoryRows && inventoryRows.length > 0 && (
                  <InventoryBar plotData={inventoryRows} axisLabel={chartGrouping} />
                )
              )}
            </Box>
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" color={'white'} sx={{ mr: '0.1rem' }}>Average Daily Sales</Typography>
              <Tooltip title="Avg daily sales are calculated based on changes to inventory; prices are based on partner sales prices found in Trinity (discounts are not accounted for at this time).">
                <IconButton sx={{ color: 'white' }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {isLoading ? (
              <>
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
              </>
            ) : (
              <>
                <Typography variant="h5" color={'white'} sx={{ mb: '0.5rem' }}>{totalSales.toLocaleString('en-US')} units / ${totalSalesDollars.toLocaleString('en-US')}</Typography>
                <Typography variant="h5" color={'white'}>${(totalSalesDollars / totalSales).toFixed(2).toLocaleString('en-US')} average price</Typography>
              </>
            )}
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: isLoading ? '1rem' : '0' }}>Sales Mix</Typography>
            <Box sx={{ height: '15vh', width: isLoading ? '100%' : '125%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                salesRows && salesRows.length > 0 && (
                  <BasicPieChart plotData={salesRows} />
                )
              )}
            </Box>
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: isLoading ? '1rem' : '0' }}>Sales Details</Typography>
            <Box sx={{ height: '15vh', width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={20} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                salesRows && salesRows.length > 0 && (
                  <InventoryBar plotData={salesRows} axisLabel={chartGrouping} />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: '50%', width: '100%', pb: '3rem', pl: '1rem' }}>
        <Typography variant="h6" color={'white'} sx={{ ml: '2rem' }}>Inventory Trend</Typography>
        {trendRows && trendRows.date && trendRows.date.length > 0 && (
          <InventoryTrend
            plotData={trendRows}
            isStacked={isStacked}
          />
        )}
      </Box>
    </>
  );
}