import React from 'react';
import { Box, Button, Select, MenuItem, Checkbox, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { createMaterialsInventory, updateMaterialsInventory, deleteMaterialsInventory } from '../../api/products';
import { fetchOrdersTo, updateOrder } from '../../api/orders';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';


export default function MaterialsInventoryDialogDatagrid({
  integrationId,
  integrationType,
  materialsRows,
  materialInventoryRows,
  setMaterialInventoryRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null
}) {
  const columnVisibilityModel = {
    id: false,
    material_id: false,
    po_sent_qty: integrationType === 'Supplier' ? false : true,
    in_transit_qty: integrationType === 'Supplier' ? false : true
  };
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);

  const handleNameChange = async (event, params) => {
    const newId = event.target.value;
    const selectedMaterial = materialsRows.find(material => material.id === newId);
    const createdInventory = await createMaterialsInventory(
      {
        integration_id: integrationId,
        material_id: selectedMaterial.id,
        qty: null,
        unit_of_measure: selectedMaterial.purchasing_uom,
        lead_time: null,
        safety_stock_qty: null
      }
    );
    setMaterialInventoryRows(prevInventory => prevInventory.filter(row => row.id !== 1).concat(createdInventory));
  };

  const handleDeleteClick = async (id) => {
    if (id !== 1) {
      try {
        const deleteSuccess = await deleteMaterialsInventory(id);
        console.log(deleteSuccess)
        setMaterialInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
        setSnackbarOpen(true);
        setSnackbarMessage("Deleted successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
    else {
      setMaterialInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchOrdersTo(row.integration_id, 'material', 'Delivered', row.material_id);
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const commonColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'material_id', headerName: 'MaterialID', flex: 1 },
    {
      field: 'name', headerName: 'Name', flex: 1, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id);
        const value = selectedMaterial ? selectedMaterial.name : '';

        return (
          value ? (
            <span>{value}</span>
          ) : (
            <Select
              value={value}
              size='small'
              variant='standard'
              onChange={(event) => handleNameChange(event, params)}
              fullWidth
            >
              {materialsRows
                .filter(material => !materialInventoryRows.some(inventory => inventory.material_id === material.id))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((material) => (
                  <MenuItem key={material.name} value={material.id}>
                    {material.name} [{material.sku}]
                  </MenuItem>
                ))}
            </Select>
          )
        );
      }
    },
    {
      field: 'sku', headerName: 'Material SKU', flex: 1, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id);
        return selectedMaterial ? selectedMaterial.sku : ''
      }
    },
    {
      field: 'po_sent_qty',
      headerName: 'Expected Materials',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'in_transit_qty',
      headerName: 'Inbound Materials',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'qty',
      headerName: 'Inventory',
      flex: 1,
      editable: true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'unit_of_measure', headerName: 'UoM', flex: 0.5, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id);
        return selectedMaterial ? selectedMaterial.purchasing_uom : ''
      }
    }
  ];

  const actionsColumn = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.75,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <>
        {
          ['Manufacturer'].includes(integrationType) && params.row.delivered_qty > 0 && (
            <Tooltip title="Material Card">
              <IconButton
                color="inherit"
                onClick={() => handleFetchInventoryDetails(params.row)}
              >
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
          )
        }
        <IconButton
          color="inherit"
          onClick={() => handleDeleteClick(params.row.id)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </>
    ),
    headerClassName: 'super-app-theme--header'
  }

  const leadTimeColumn = { field: 'lead_time', headerName: 'Lead Time (days)', flex: 1, editable: true }

  const turnKeyColumn = {
    field: 'is_turnkey',
    headerName: 'Manufacturer Sourced',
    flex: 0.75,
    editable: true,
    renderCell: (params) => (
      <Checkbox
        checked={!!params.value}
        onChange={(event) => {
          const newValue = event.target.checked;
          const updatedRow = { ...params.row, is_turnkey: newValue };
          processRowUpdate(updatedRow);
        }}
        sx={{
          color: 'black',
          '&.Mui-checked': {
            color: 'black',
          },
        }}
      />
    )
  }

  let columns = [...commonColumns];

  if (['Manufacturer'].includes(integrationType)) {
    columns.push(turnKeyColumn);
    columns.push(actionsColumn);
  } else if (['Supplier'].includes(integrationType)) {
    columns.push(leadTimeColumn);
    columns.push(actionsColumn);
  }

  const handleAddMaterialInventory = async () => {
    const hasId1 = materialInventoryRows.some(row => row.id === 1);
    if (!hasId1) {
      const createdMaterialInventory =
      {
        id: 1,
        integration_id: integrationId,
        material_id: null,
        qty: null,
        unit_of_measure: null,
        lead_time: null,
        is_turnkey: null
      }
      setMaterialInventoryRows(prevInventory => [...prevInventory, createdMaterialInventory]);
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddMaterialInventory}
        >
          Add Material
        </Button>
      </GridToolbarContainer>
    );
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (newRow.qty >= 0) {
      try {
        const data = await updateMaterialsInventory(newRow.id, newRow);
        updatedRow.last_updated = data.last_updated;
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully");
        setSnackbarAlert("success");
        setMaterialInventoryRows(materialInventoryRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } catch (error) {
        throw error
      }
    }
  };

  const processOrderRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow };
    try {
      const data = await updateOrder(newRow.id, newRow, 'material');
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setInventoryDetailsRows(inventoryDetailsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      throw error
    }
  };

  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage("Invalid value entered");
    setSnackbarAlert("error");
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setInventoryDetailsRows([])}
        >
          Back to table
        </Button>
      </GridToolbarContainer>
    );
  };

  const detailsColumns = [
    { field: 'id', headerName: 'ID', width: 80, headerClassName: 'super-app-theme--header' },
    { field: 'name', headerName: 'Material', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lot_number', headerName: 'Lot', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'expiration_date_raw', headerName: 'Expiration Date', editable: true, flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'unit_price', headerName: 'Order Price ($)', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'landed_price',
      headerName: 'Landed Cost ($)',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? params.value.toFixed(2) : ''
    },
    {
      field: 'qty',
      headerName: 'Order Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'invoice_qty',
      headerName: 'Invoice Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : ''
    },
    {
      field: 'est_arrival',
      headerName: 'Arrival Date',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy');
      },
    }
  ];


  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: '50vh' }}>
        {inventoryDetailsRows.length === 0 ? (
          <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh', mr: inventoryDetailsRows.length ? '1rem' : '0' }}>
            <DataGrid
              rows={materialInventoryRows}
              columns={columns}
              density='compact'
              hideFooter
              loading={loading}
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh' }}>
            <DataGrid
              rows={inventoryDetailsRows}
              columns={detailsColumns}
              density='compact'
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processOrderRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: CustomDetailsToolbar,
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
