import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import ToggleButton from '@mui/material/ToggleButton';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import AllInboxIcon from '@mui/icons-material/AllInbox';

export default function ToggleDetailsView({ integrationType, currentView, setCurrentView }) {
  const buttonStyles = {
    color: 'black',
    backgroundColor: 'transparent',
    border: '1px solid black',
    '&.Mui-selected': {
      backgroundColor: 'grey.900',
      color: 'white',
      '&:hover': {
        backgroundColor: 'black',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  };

  const handleViewChange = (event, newValue) => {
    if (newValue !== null) {
      setCurrentView(newValue);
    }
  };

  return (
    <>
      {['Supplier'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="orders" aria-label="centered" sx={buttonStyles}>
            <ViewListIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Purchase Orders</Typography>
          </ToggleButton>
          <ToggleButton value="materials_inventory" aria-label="materials" sx={buttonStyles}>
            <OilBarrelIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Materials</Typography>
          </ToggleButton>
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          {/* <ToggleButton value="financials" aria-label="financials" sx={buttonStyles}>
            <AttachMoneyIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Financials</Typography>
          </ToggleButton> */}
          {/* <ToggleButton value="inbox" aria-label="inbox" sx={buttonStyles}>
            <AllInboxIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbox</Typography>
          </ToggleButton> */}
        </ToggleButtonGroup>
      )}

      {['3PL', 'Distributor'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="inventory" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
          <ToggleButton value="incoming" aria-label="incoming" sx={buttonStyles}>
            <LocalShippingIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbound Shipments</Typography>
          </ToggleButton>
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          {/* <ToggleButton value="financials" aria-label="financials" sx={buttonStyles}>
            <AttachMoneyIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Financials</Typography>
          </ToggleButton> */}
          {/* <ToggleButton value="inbox" aria-label="inbox" sx={buttonStyles}>
            <AllInboxIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbox</Typography>
          </ToggleButton> */}
        </ToggleButtonGroup>
      )}
      {['Manufacturer'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="orders" aria-label="orders" sx={buttonStyles}>
            <ViewListIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Purchase Orders</Typography>
          </ToggleButton>
          <ToggleButton value="inventory" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
          <ToggleButton value="materials_inventory" aria-label="materials" sx={buttonStyles}>
            <OilBarrelIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Materials</Typography>
          </ToggleButton>
          <ToggleButton value="incoming" aria-label="incoming" sx={buttonStyles}>
            <LocalShippingIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbound Shipments</Typography>
          </ToggleButton>
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          {/* <ToggleButton value="financials" aria-label="financials" sx={buttonStyles}>
            <AttachMoneyIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Financials</Typography>
          </ToggleButton> */}
          {/* <ToggleButton value="inbox" aria-label="inbox" sx={buttonStyles}>
            <AllInboxIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbox</Typography>
          </ToggleButton> */}
        </ToggleButtonGroup>
      )}
      {['HQ'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="products" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
          <ToggleButton value="materials" aria-label="materials" sx={buttonStyles}>
            <OilBarrelIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Materials</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
}
