import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
// import { BarChart } from '@mui/x-charts/BarChart';


export default function FinancialsDatagrid({ rows, loading = null }) {

  const columns = [
    {
      field: 'date',
      headerName: 'Transaction Date',
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy');
      },
    },
    { field: 'type', headerName: 'Type', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'desc', headerName: 'Description', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'account', headerName: 'Account', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const value = params.value || 0;
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      },
    },
  ];

  return (
    <>
      <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh' }}>
        <DataGrid
          rows={rows}
          loading={loading}
          columns={columns}
          density='compact'
          hideFooter
        />
      </Box>
      {/* <Box sx={{ width: '70%', height: '50vh', maxHeight: '50vh' }}>
        <BarChart
          dataset={rows}
          series={[
            { dataKey: 'amount', color: '#292828' },
          ]}
          xAxis={[{ scaleType: 'band', dataKey: 'transaction_month' }]}
          slotProps={{ legend: { hidden: true } }}
        />
      </Box> */}
    </>
  );
}
