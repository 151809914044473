import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import tiktik_logo from '../assets/icons8-tik-tok-50.png';

const TikTokLink = () => {


  return (
    <>
      <Card sx={{ width: '350px', height: '150px' }}>
        <CardActionArea sx={{ p: 1, height: '100%' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={tiktik_logo} alt="TikTok Logo" style={{ width: '35px', height: '35px' }} />
            </Box>
            <Typography variant="body1" color="black">
              TikTok Shop
            </Typography>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link inventory from TikTok shop.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default TikTokLink;