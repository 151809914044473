import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { createIntegration } from '../api/integrations';
import { axiosInstance, checkToken } from '../api/utils';
import SearchIcon from '@mui/icons-material/Search';
import CustomTextField from './CustomTextField';
import LabeledDropdown from './LabeledDropdown';

const CreateIntegrationDialog = ({
  dialogOpen,
  handleClose,
  integrationTypes,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert
}) => {
  const [name, setName] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactTitle, setContactTitle] = useState('');
  const [moq, setMoq] = useState('');
  const [moqUnits, setMoqUnits] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [type, setType] = useState('');
  const [typeName, setTypeName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [isBrandOperated, setIsBrandOperated] = useState(false);
  const [is3PL, setIs3PL] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const [showStreetAddress, setShowStreetAddress] = useState(false);
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);


  const handleLocationLookup = async () => {
    try {
      const token = await checkToken();
      const response = await axiosInstance(token).post('/location_lookup/', { location: locationSearch });
      setStreetAddress(response.data.body[0].formatted_address);
      setBillingAddress(response.data.body[0].formatted_address);
      setLat(response.data.body[0].geometry.location.lat);
      setLong(response.data.body[0].geometry.location.lng);
      setShowStreetAddress(true);
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsLoading(true);
      await handleLocationLookup();
      setIsLoading(false);
    }
  };

  const handleSearchPress = async () => {
    setIsLoading(true);
    await handleLocationLookup();
    setIsLoading(false);
  };

  const handleTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    const selectedType = integrationTypes.find(type => type.id === selectedTypeId);
    setType(selectedTypeId);
    setTypeName(selectedType.type)
  };

  const handleValidate = () => {
    if (name === '' || contactName === '' || streetAddress === '') {
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage("Please complete remaining form items");
      return false;
    }
    return true;
  };


  const handleSubmit = async () => {
    try {
      if (handleValidate()) {
        const newIntegration = {
          integration_type_id: type,
          name,
          contact_name: contactName,
          is_brand_operated: isBrandOperated,
          is_3pl: is3PL,
          minimum_order_qty: moq || null,
          minimum_order_units: moqUnits || '',
          contact_title: contactTitle || '',
          contact_email: contactEmail,
          street_address: streetAddress,
          billing_address: billingAddress,
          config: {},
          coordinates: { long, lat }
        };

        const integrationSuccess = await createIntegration(newIntegration);
        setIntegrations((prevIntegrations) => [...prevIntegrations, integrationSuccess]);
        setName('');
        setContactTitle('');
        setContactName('');
        setContactEmail('');
        setMoq('');
        setMoqUnits('');
        setType('');
        setIsBrandOperated(false);
        setLocationSearch('');
        setStreetAddress('');
        setBillingAddress('');
        setShowStreetAddress(false);
        handleClose();
      }
    } catch (error) {
      console.error('Error creating integration:', error);
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage(error.response.data.detail);
    }
  };


  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogTitle>Add Partner</DialogTitle>
      <DialogContent>
        <LabeledDropdown
          label="Type"
          value={type}
          sx={{ my: '0.5rem' }}
          options={integrationTypes.map(row => ({ value: row.id, label: row.type }))}
          onChange={handleTypeChange}
        />
        <CustomTextField
          label="Partner Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {['3PL', 'Manufacturer', 'Distributor'].includes(typeName) && typeName && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isBrandOperated}
                onChange={(e) => setIsBrandOperated(e.target.checked)}
                name="isBrandOperated"
                color="primary"
                sx={{ ml: '0.3rem' }}
              />
            }
            label="Brand Operated?"
          />
        )}
        {['Manufacturer'].includes(typeName) && typeName && (
          <FormControlLabel
            control={
              <Checkbox
                checked={is3PL}
                onChange={(e) => setIs3PL(e.target.checked)}
                name="Is 3PL"
                color="primary"
                sx={{ ml: '0.3rem' }}
              />
            }
            label="Is 3PL?"
          />
        )}
        <CustomTextField
          label="Contact Name"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
        />
        <CustomTextField
          label="Contact Title"
          value={contactTitle}
          onChange={(e) => setContactTitle(e.target.value)}
          required={false}
        />
        <CustomTextField
          label="Contact Email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        {!['3PL', 'HQ', 'Distributor'].includes(typeName) && (
          <>
            <CustomTextField
              label="Minimum Order Quantity"
              type='number'
              value={moq}
              required={false}
              onChange={(e) => setMoq(e.target.value)}
            />
            <CustomTextField
              label="Unit of Measure"
              value={moqUnits}
              required={false}
              onChange={(e) => setMoqUnits(e.target.value)}
            />
          </>
        )}
        {!isLoading ? (
          <>
            <Box fullWidth sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ width: '80%' }}>
                <CustomTextField
                  label="Search Address"
                  value={locationSearch}
                  onChange={(e) => setLocationSearch(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </Box>
              <Box sx={{ ml: '5px' }}>
                <Button sx={{ m: '0.3rem' }} variant='outlined' startIcon={<SearchIcon />} onClick={handleSearchPress}>Search</Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box fullWidth sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={24} style={{ color: 'black', marginTop: '10px' }} />
          </Box>
        )}
        {showStreetAddress && !isLoading && (
          <>
            <Typography sx={{ m: '0.5rem' }}>
              Formatted Street Address: <b>{streetAddress}</b>
            </Typography>
            <FormControlLabel
              sx={{ ml: '0.2rem', mb: '0.5rem' }}
              control={
                <Checkbox
                  checked={isBillingSameAsShipping}
                  onChange={(event) => setIsBillingSameAsShipping(event.target.checked)}
                  name="billingSameAsShipping"
                />
              }
              label="Billing is same as shipping"
            />
            {!isBillingSameAsShipping && (
              <CustomTextField
                label="Billing Address"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateIntegrationDialog;
