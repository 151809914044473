import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import gmail_logo from '../assets/icons8-gmail-48.png';
import sheets_logo from '../assets/icons8-google-sheets-48.png';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

function GoogleAuth({ type = 'Gmail', gmailLogin }) {
  const [googleAuthDialogOpen, setGoogleAuthDialogOpen] = useState(false);

  return (
    <>
      <Card sx={{ width: '350px', height: '150px' }}>
        <CardActionArea onClick={() => setGoogleAuthDialogOpen(true)} sx={{ p: 1, height: '100%' }}>
          <CardContent>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img src={type === 'Gmail' ? gmail_logo : sheets_logo} alt="Gmail Logo" style={{ width: '35px', height: '35px' }} />
            </Box>
            <Typography variant="body1" color="black">
              {type}
            </Typography>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }}>
              {type === 'Gmail' ? 'Allow Trinity to automatically extract information from emails' : 'Link data from Google Sheets'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={googleAuthDialogOpen}
        onClose={() => setGoogleAuthDialogOpen(false)}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Confirm Gmail integration</DialogTitle>
        <DialogContent>
          <Typography
            component="div"
            sx={{
              color: 'text.secondary',
              mb: '1rem',
              border: '1px solid',
              borderRadius: '15px',
              p: '0.5rem',
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              flexDirection: 'column',
              textAlign: 'left',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
              <HttpsOutlinedIcon sx={{ marginRight: '0.25em', color: 'primary.main' }} />
              <b>Trinity takes privacy and security seriously.</b>
            </Box>
            <span>
              <br />
              Our system applies filters to the "from" email field to ensure that only emails relevant to supply chain partners are opened and processed.<br /><br />
              You can unlink your email at any time from within your Google account. <br /><br />
              For more details, please refer to our&nbsp;
              <a href="https://trinity3.co/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline' }}>privacy policy</a>
              &nbsp;and&nbsp;
              <a href="https://trinity3.co/terms-of-service/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline' }}>terms of service</a>.
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGoogleAuthDialogOpen(false)} variant="text">Close</Button>
          <Button onClick={() => {
            gmailLogin();
            setGoogleAuthDialogOpen(false);
          }}
            variant="text">Authorize</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GoogleAuth;
