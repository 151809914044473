import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import {
  IconButton,
  Tooltip,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreatePODialog from '../CreatePODialog';

export default function OrdersMainDatagrid({
  rows,
  setRows,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  selectedStatus,
  setSelectedStatus,
  hiddenColumns = ['id'],
  handleOpenDialog = null,
  showButton = true,
  loading = null
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const columnVisibilityModel = hiddenColumns.reduce((acc, column) => {
    acc[column] = false;
    return acc;
  }, {});

  const statusItems = [
    { value: 'All Orders', label: 'All Orders' },
    { value: 'Draft', label: 'Draft' },
    { value: 'PO Sent', label: 'PO Sent' },
    { value: 'In Transit', label: 'In Transit' },
    { value: 'Delivered', label: 'Delivered' },
  ];

  const columns = [
    { field: 'id', headerName: 'ID', width: 80, headerClassName: 'super-app-theme--header' },
    { field: 'name', headerName: 'Item', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'sku', headerName: 'SKU', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'integration_name', headerName: 'From Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'integration_type', headerName: 'Type', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'integration_to_name', headerName: 'To Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'order_name', headerName: 'PO Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'status', headerName: 'Status', display: 'flex', headerClassName: 'super-app-theme--header' },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    { field: 'unit_of_measure', headerName: 'UoM', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'est_arrival', headerName: 'Est Arrival', width: 80,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
    },
    { field: 'edited_by', headerName: 'Source', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'schedule_date', headerName: 'Order By', width: 80,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
      cellClassName: 'super-app-theme-editable--cell',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'super-app-theme-editable--cell',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <>
          {params.row.status === 'Draft' && (
            <>
              <Tooltip title="Send PO">
                <IconButton
                  color="inherit"
                  onClick={() => handleOpenDialog(params.row)}
                >
                  <EmailOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      ),
    }
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create Draft PO
        </Button>
        <GridToolbarContainer>
          <Select
            value={selectedStatus}
            size='small'
            variant='standard'
            sx={{ width: '250px' }}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            {statusItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </GridToolbarContainer>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        loading={loading}
        columnVisibilityModel={columnVisibilityModel}
        density='compact'
        slots={{
          toolbar: showButton ? Toolbar : null,
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row': {
            color: 'black',
          },
        }}
      />
      {showButton && (
        <CreatePODialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          setRows={setRows}
          integrations={integrations}
          productsRows={productsRows}
          materialsRows={materialsRows}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
    </>
  )
}