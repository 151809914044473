import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { deleteIntegration } from '../../api/integrations';
import CreateIntegrationDialog from '../CreateIntegrationDialog';


export default function IntegrationMainDatagrid({
  integrations,
  setIntegrations,
  integrationTypes,
  setSelectedIntegrationId,
  setDialogOpen,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading
}) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedName, setSelectedName] = useState('');


  const handleChartClick = (id) => {
    setSelectedIntegrationId(id);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  const handleDeleteClick = (name) => {
    setDeleteDialogOpen(true);
    setSelectedName(name);
  };

  const handleDeleteConfirm = async () => {
    try {
      const integrationDeleteSuccess = await deleteIntegration(selectedName);
      console.log(integrationDeleteSuccess)
      setIntegrations((prevIntegrations) => prevIntegrations.filter(integration => integration.name !== selectedName));
      setDeleteDialogOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Integration deleted.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedName('')
  };


  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'integration_type',
      headerName: 'Type',
      flex: 1,
      valueGetter: (value, row) => row.integration_type.type,
      headerClassName: 'super-app-theme--header'
    },
    { field: 'contact_name', headerName: 'Contact Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'contact_email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="View Partner Card">
            <IconButton
              color="inherit"
              onClick={() => handleChartClick(params.row.id)}
            >
              <BarChartIcon />
            </IconButton>
          </Tooltip>
          {params.row.integration_type.type !== 'HQ' && (
            <IconButton
              color="inherit"
              onClick={() => handleDeleteClick(params.row.name)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() => setCreateDialogOpen(true)}
        >
          Add Partner
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={integrations}
        columns={columns}
        loading={loading}
        hideFooter
        density='compact'
        slots={{
          toolbar: Toolbar,
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row': {
            color: 'black',
          },
        }}
      />
      <CreateIntegrationDialog
        dialogOpen={createDialogOpen}
        handleClose={handleClose}
        integrationTypes={integrationTypes.filter(integration => integration.type !== 'HQ')}
        setIntegrations={setIntegrations}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
      />
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Integration</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete{' '}
            <b>{selectedName}</b>?<br /><br />
            <b>This will also delete all associated inventory and orders.</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={() => handleDeleteConfirm()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )

}