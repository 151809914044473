
import { axiosInstance, checkToken } from './utils.js'

const fetchShipments = async (integration_type = null, integration_id_to = null, product_id = null, material_id = null) => {
  try {
    const token = await checkToken();
    const params = new URLSearchParams();
    if (integration_type) params.append('integration_type', integration_type);
    if (integration_id_to) params.append('integration_id_to', integration_id_to);
    if (product_id) params.append('product_id', product_id);
    if (material_id) params.append('material_id', material_id);
    const queryString = params.toString();
    const url = '/shipments/' + (queryString ? `?${queryString}` : '');
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createShipment = async (shipmentData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/shipments/', shipmentData);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};


const updateShipmentDocument = async (data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/shipments/`, data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export { fetchShipments, updateShipmentDocument, createShipment };
