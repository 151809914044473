import React, { useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { updateOrder, createOrders, deleteOrder, fetchOrderLogs } from '../../api/orders';
import { fetchMaterialsInventory, fetchInventory } from '../../api/products';
import { fetchProductLimits, fetchProductLimitDetails } from '../../api/forecast';
import CustomTextField from '../CustomTextField'
import POSender from '../POSender'
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

const OrdersDialogDatagrid = ({
  logoBlob,
  orderType,
  rows,
  setRows,
  materialsRows,
  productsRows,
  integration,
  integrations,
  tableView,
  setTableView,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading=null
}) => {
  const [rowLogs, setRowLogs] = useState([]);
  const [productLimits, setProductLimits] = useState([]);
  const [productLimitDetails, setProductLimitDetails] = useState([]);
  const [poNumber, setPONumber] = useState('');
  const [selectedPOsArr, setSelectedPOsArr] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [validRowOptions, setValidRowOptions] = useState([]);
  const columnVisibilityModel = { id: false };


  const handleFetchLogs = async (order_id) => {
    try {
      const data = await fetchOrderLogs(order_id, orderType);
      setRowLogs(data);
      setTableView('logs');
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateOrder(newRow.id, newRow, orderType);
      updatedRow.last_updated = data.last_updated;
      updatedRow.status = data.status;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error('Error updating row:', error);
      return null;
    }
  };


  const handleAddItem = () => {
    if (orderType === 'material') {
      setSkuList(
        [...skuList,
        {
          material_id: materialsRows[0].id,
          qty: integration.minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: ''
        }
        ]);
    } else {
      setSkuList(
        [...skuList,
        {
          product_id: productsRows[0].id,
          qty: integration.minimum_order_qty,
          unit_of_measure: integration.minimum_order_units,
          unit_price: ''
        }
        ]);
    }
  }

  const handleRemoveItem = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const handleValidate = () => {
    if (poNumber.trim() === '') {
      setSnackbarAlert("error")
      setSnackbarMessage("Please name the PO")
      setSnackbarOpen(true)
      return false;
    }

    if (skuList.length === 0) {
      setSnackbarAlert("error")
      setSnackbarMessage("Please add items to the PO")
      setSnackbarOpen(true)
      return false;
    }

    for (const item of skuList) {
      if (!item[`${orderType}_id`] || item.qty === '' || item.unit_of_measure === '' || item.unit_price === '') {
        setSnackbarAlert("error")
        setSnackbarMessage("Please complete all items")
        setSnackbarOpen(true)
        return false;
      }
      else if (item.qty < integration.minimum_order_qty && item.unit_of_measure === integration.minimum_order_units) {
        setSnackbarAlert("error")
        setSnackbarMessage("Minimum quantity violated")
        setSnackbarOpen(true)
        return false;
      }
    }

    return true;
  };

  const handleSkuInputChange = (index, field, value) => {
    const newSkuList = [...skuList];
    newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
    if (field === `${orderType}_id` && orderType === "material") {
      newSkuList[index]['unit_of_measure'] = materialsRows.filter(row => row.id === value)[0].purchasing_uom;
    }
    setSkuList(newSkuList);
  };

  const handleCreateOrders = async () => {
    if (handleValidate()) {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: integration.id,
        integration_id_to: null,
        order_name: poNumber,
        status: "Draft",
        tax_rate: item.tax_rate / 100
      }));
      try {
        const data = await createOrders(updatedSkuList, orderType);
        setSnackbarOpen(true);
        setSnackbarMessage("Order created successfully.");
        setSnackbarAlert("success");
        setRows(prevRows => [...prevRows, ...data]);
        setSkuList([]);
        setPONumber('');
        setTableView('table');
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
  };


  const handleShowEmail = async (order) => {
    setTableView('sending_po');
    const ordersArr = rows.filter(row => row.order_name === order.order_name);
    setSelectedPOsArr(ordersArr);
  };

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrder(id, orderType);
      setRows((prevOrders) => prevOrders.filter(order => order.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Order deleted.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'sku', headerName: 'SKU', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'status', headerName: 'Status', flex: 0.75, headerClassName: 'super-app-theme--header' },
    { field: 'qty', headerName: 'Qty', flex: 0.5, headerClassName: 'super-app-theme--header' },
    { field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, headerClassName: 'super-app-theme--header' },
    { field: 'unit_price', headerName: 'Price', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'est_arrival', headerName: 'Est Arrival', width: 80,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      display: 'flex',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Show History">
            <IconButton
              color="inherit"
              onClick={() => handleFetchLogs(params.row.id)}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
          {params.row.status === 'Draft' && (
            <>
              <Tooltip title="Send PO">
                <IconButton
                  color="inherit"
                  onClick={() => handleShowEmail(params.row)}
                >
                  <EmailOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteOrder(params.row.id)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const columnLogs = [
    { field: 'id', headerName: 'ID' },
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty', headerName: 'Qty', display: 'flex', headerClassName: 'super-app-theme--header' },
    { field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, headerClassName: 'super-app-theme--header' },
    { field: 'unit_price', headerName: 'Unit Price', display: 'flex', headerClassName: 'super-app-theme--header' },
    { field: 'edited_by', headerName: 'Edited By', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'est_arrival', headerName: 'Est Arrival', width: 80,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'create_time',
      headerName: 'Updated At',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    }
  ];

  const handleShowLimitDetails = async (product_id) => {
    const productLimitDetailsData = await fetchProductLimitDetails(integration.id, product_id);
    setProductLimitDetails(productLimitDetailsData);
  };

  const columnLimits = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name', headerName: 'Product', flex: 1, headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() => handleShowLimitDetails(params.row.id)}
          >
            <FormatListNumberedIcon />
          </IconButton>
          <span>{params.value}</span>
        </>
      )
    },
    {
      field: 'qty', headerName: (
        <Box display="flex" alignItems="center">
          <Typography variant="inherit" sx={{ ml: 0.25, display: 'flex', alignItems: 'center' }}>
            Max Qty
            <Tooltip title="Maximum quantity of finished goods based on current raw material levels.">
              <InfoOutlinedIcon fontSize="small" sx={{ ml: 0.25 }} />
            </Tooltip>
          </Typography>
        </Box>
      ), flex: 1, headerClassName: 'super-app-theme--header'
    }
  ];

  const columnLimitDetails = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Material', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'material_qty', headerName: 'Raw Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty_per_product', headerName: 'Unit Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty', headerName: 'Finished Goods Qty', flex: 1, headerClassName: 'super-app-theme--header' }
  ];


  const renderOptions = () => {
    if (integration.integration_type.type === 'Supplier') {
      return materialsRows
        .filter(material => validRowOptions.includes(material.id))
        .map(material => (
          <MenuItem key={material.id} value={material.id}>
            {material.name}
          </MenuItem>
        ))
    } else {
      return productsRows
        .filter(product => validRowOptions.includes(product.id))
        .map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name}
          </MenuItem>
        ));
    }
  };

  const handleAddClick = async () => {
    if (orderType === 'product') {
      const data = await fetchInventory(integration.id);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.product_id);
      setValidRowOptions(validIds);
      const productLimitData = await fetchProductLimits(integration.id);
      setProductLimits(productLimitData);
    } else {
      const data = await fetchMaterialsInventory(integration.id);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.material_id);
      setValidRowOptions(validIds);
    }

    setTableView("adding_order")
  };


  const OrdersToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Create PO
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <>
      {!['adding_order', 'logs', 'sending_po'].includes(tableView) && (
        <>
          <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density='compact'
              loading={loading}
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processRowUpdate}
              hideFooter
              slots={{
                toolbar: OrdersToolbar,
              }}
            />
          </Box>
        </>
      )}
      {
        tableView === 'logs' ? (
          <>
            <Box sx={{ width: '100%', height: '50vh', maxHeight: '50vh' }}>
              <DataGrid
                rows={rowLogs}
                columns={columnLogs}
                columnVisibilityModel={columnVisibilityModel}
                density='compact'
                hideFooter
              />
            </Box>
          </>
        ) : tableView === 'adding_order' ? (
          <>
            <Box sx={{ width: orderType === "product" ? '65%' : "85%", height: '50vh', maxHeight: '50vh' }}>
              {(orderType === 'material' && materialsRows.length === 0) ? (
                <Typography color={'red'}>You must first add materials in the HQ</Typography>
              ) : (orderType === 'product' && productsRows.length === 0) ? (
                <Typography color={'red'}>You must first add products in the HQ</Typography>
              ) : (
                <>
                  <CustomTextField
                    label="PO Number"
                    size='small'
                    variant="outlined"
                    fullWidth
                    sx={{ width: '35%' }}
                    value={poNumber}
                    onChange={(e) => setPONumber(e.target.value)}
                  />
                  <List sx={{ maxHeight: '40vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
                    {skuList.map((item, index) => (
                      <ListItem key={index} sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
                        <FormControl size='small' fullWidth sx={{ mr: '5px' }}>
                          <InputLabel id={item.id}>Item</InputLabel>
                          <Select
                            labelId={item.id}
                            id={item.id}
                            key={item.id}
                            label="Item"
                            value={item[`${orderType}_id`]}
                            onChange={(e) => handleSkuInputChange(index, `${orderType}_id`, e.target.value)}
                          >
                            {renderOptions()}
                          </Select>
                        </FormControl>
                        <CustomTextField
                          label="Qty"
                          type='number'
                          value={item.qty}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
                        />
                        <CustomTextField
                          label="UoM"
                          disabled
                          value={item.unit_of_measure}
                          sx={{ mr: '5px', width: '50%' }}
                        />
                        <CustomTextField
                          label="Unit Price ($)"
                          type='number'
                          value={item.unit_price}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
                        />
                        <CustomTextField
                          label="Tax Rate (%)"
                          type='number'
                          value={item.tax_rate}
                          onChange={(e) => handleSkuInputChange(index, 'tax_rate', e.target.value)}
                        />
                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <DeleteOutlineIcon sx={{ color: 'black' }} />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    color="primary"
                    variant='outlined'
                    sx={{ mr: '0.25rem' }}
                    startIcon={<AddIcon />}
                    onClick={handleAddItem}
                  >
                    Add Item
                  </Button>
                </>
              )}
              {skuList.length > 0 && (
                <Button
                  color="primary"
                  variant='outlined'
                  sx={{ m: '0.25rem' }}
                  onClick={() => handleCreateOrders(skuList)}
                >
                  Create
                </Button>
              )}
              <Button
                color="primary"
                variant='outlined'
                sx={{ m: '0.25rem' }}
                onClick={() => setTableView('table')}
              >
                Cancel
              </Button>
            </Box >
            {orderType === "product" && (
              <Box sx={{ width: '35%', height: '50vh', maxHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column' }}>
                <Typography>Click a product to view material details</Typography>
                <Box sx={{ width: '95%', height: '25vh', maxHeight: '25vh', mb: '0.5rem' }}>
                  <DataGrid
                    rows={productLimits}
                    columns={columnLimits}
                    columnVisibilityModel={columnVisibilityModel}
                    density='compact'
                    hideFooter
                  />
                </Box>
                <Box sx={{ width: '95%', height: '25vh', maxHeight: '25vh' }}>
                  <DataGrid
                    rows={productLimitDetails}
                    columns={columnLimitDetails}
                    columnVisibilityModel={columnVisibilityModel}
                    density='compact'
                    hideFooter
                  />
                </Box>
              </Box>
            )}
          </>
        ) : tableView === 'sending_po' ? (
          <POSender
            logoBlob={logoBlob}
            selectedPOsArr={selectedPOsArr}
            setSelectedPOsArr={setSelectedPOsArr}
            orderType={orderType}
            rows={rows}
            setRows={setRows}
            setTableView={setTableView}
            integrations={integrations}
            integration={integration}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
          />
        ) : null
      }
    </>
  );
};

export default OrdersDialogDatagrid;
