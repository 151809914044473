import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  List,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  createDocument,
} from '../../api/documents.js';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { updateShipmentDocument, createShipment } from '../../api/shipments.js';
import { fetchOrders } from '../../api/orders.js';
import AddIcon from '@mui/icons-material/Add';
import LabeledDropdown from '../LabeledDropdown';
import CustomTextField from '../CustomTextField';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';


export default function ShipmentsDatagrid({
  rows,
  addingShipment,
  setAddingShipment,
  setRows,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading = null,
  showToolbar = false,
  customToolbar = null,
}) {
  const columnVisibilityModel = { id: false };
  const [skuList, setSkuList] = useState([]);
  const [isExistingPO, setIsExistingPO] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [bolNumber, setBolNumber] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedPartnerTo, setSelectedPartnerTo] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedPO, setSelectedPO] = useState("");
  const [orderType, setOrderType] = useState('product');
  const fileInputRefs = useRef({});

  const columns = [
    { field: 'id', headerName: 'ID', width: 80, headerClassName: 'super-app-theme--header' },
    { field: 'receiving_order_name', headerName: 'WRO Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'order_name', headerName: 'PO Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'name', headerName: 'Item', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'lot_number', headerName: 'Lot', flex: 1.0, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'expiration_date_raw', headerName: 'Exp. Date', flex: 1.0, editable: true, headerClassName: 'super-app-theme--header' },
    {
      field: 'shipment_qty',
      headerName: 'Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    { field: 'carrier', headerName: 'Carrier', flex: 1.0, editable: true, headerClassName: 'super-app-theme--header' },
    {
      field: 'pick_up_date',
      headerName: 'Pickup Date',
      flex: 1,
      editable: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.pick_up_date ? format(new Date(params.row.pick_up_date), 'MM/dd/yyyy') : ''
    },
    {
      field: 'delivery_date',
      headerName: 'ETA',
      editable: true,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.delivery_date ? format(new Date(params.row.delivery_date), 'MM/dd/yyyy') : ''
    },
    { field: 'bol_number', headerName: 'BOL Number', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'tracking_number', headerName: 'Tracking', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'integration_name', headerName: 'From Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'integration_name_to', headerName: 'To Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'document_names',
      headerName: 'Documents',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          {params.value && (
            <Tooltip title={params.value && params.value.join(', ')}>
              <IconButton>
                <InsertDriveFileOutlinedIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={() => handleUploadClick(params.row.id)}>
            <NoteAddOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
          <input
            type="file"
            ref={getFileInputRef(params.row.id)}
            style={{ display: 'none' }}
            onChange={(e) =>
              handleFileChange(e, params.row.integration_id, params.row.id, params.row.receiving_order_name)
            }
          />
        </Box>
      ),
    },
    {
      field: 'is_delivered', headerName: 'Delivered?', editable: true, flex: 0.85, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_delivered: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
  ]

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      const updatedShipments = await updateShipmentDocument([newRow]);
      setRows((prevRows) => {
        return prevRows.map((row) => {
          const updatedShipment = updatedShipments.find((shipment) => shipment.id === row.id);
          return updatedShipment ? updatedShipment : row;
        });
      });

      const updatedRow = updatedShipments.find((shipment) => shipment.id === newRow.id);
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      return updatedRow || oldRow;

    } catch (error) {
      console.error('Error updating shipment:', error);
      setSnackbarOpen(true);
      setSnackbarMessage("Error updating shipment.");
      setSnackbarAlert("error");
      return oldRow;
    }
  };

  const handleAddShipment = () => {
    setAddingShipment(true);
  };

  const handleSkuInputChange = (index, field, value) => {
    const newSkuList = [...skuList];
    newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
    setSkuList(newSkuList);
  };

  const handleRemoveItem = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const getFileInputRef = (rowId) => {
    if (!fileInputRefs.current[rowId]) {
      fileInputRefs.current[rowId] = React.createRef();
    }
    return fileInputRefs.current[rowId];
  };

  const handleUploadClick = (rowId) => {
    const inputRef = getFileInputRef(rowId);
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event, rowId, integrationId, receiving_order_name) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      uploadDocument(file, integrationId, rowId, receiving_order_name);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDFs are allowed.');
    }
  };

  const uploadDocument = async (file, rowId, integrationId, receiving_order_name) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        await createDocument(integrationId, receiving_order_name + '_' + file.name, b64encodedString, rowId);
        setRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.id === rowId) {
              return {
                ...row,
                document_names: row.document_names ? [...row.document_names, receiving_order_name + '_' + file.name] : [receiving_order_name + '_' + file.name]
              };
            }
            return row;
          });
        });
        setSnackbarAlert('success');
        setSnackbarOpen(true);
        setSnackbarMessage('Document uploaded.');
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      }
    };
  };


  const handleAddItem = () => {
    if (orderType === 'material') {
      setSkuList(
        [...skuList,
        {
          material_id: materialsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: ''
        }
        ]);
    } else {
      setSkuList(
        [...skuList,
        {
          product_id: productsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_price: ''
        }
        ]);
    }
  }


  const handleSaveShipment = async () => {
    try {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: selectedPartner,
        integration_id_to: selectedPartnerTo || null,
        order_name: orderNumber,
        carrier: String(carrierName),
        bol_number: parseInt(bolNumber, 10),
        tracking_number: String(trackingNumber),
        qty: item.qty,
        unit_price: item.unit_price || null,
        is_existing_order: isExistingPO,
        order_type: orderType
      }));
      console.log(updatedSkuList);
      const response = await createShipment(updatedSkuList);
      setRows([...rows, ...response]);
      setAddingShipment(false);
      setSnackbarAlert("success");
      setSnackbarOpen(true);
      setSnackbarMessage("Shipment created successfully.");
    } catch (error) {
      console.error('Error saving shipment:', error);
      setSnackbarAlert("error");
      setSnackbarOpen(true);
      setSnackbarMessage("There was an error creating the shipment.");
    }
  }

  const handleLoadOptions = async (integration_id) => {
    setLoadingOptions(true);
    try {
      const orderType = integrations.find((integration) => integration.id === integration_id)?.integration_type.category === 'Supplier' ? 'material' : 'product';
      const data = await fetchOrders(integration_id, orderType, 'PO Sent');
      setOrderDetails(data);
      const distinctOrderNames = [...new Set(data.map(order => order.order_name))];
      setOptions(distinctOrderNames);
    } catch (error) {
      console.error('Error:', error);
      setOptions([]);
    }
    setLoadingOptions(false);
  };

  const renderOptions = () => {
    if (orderType === 'material' && materialsRows.length > 0) {
      return materialsRows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(material => (
          <MenuItem key={material.id} value={material.id}>
            {material.name} [{material.sku}]
          </MenuItem>
        ));
    } else if (productsRows.length > 0) {
      return productsRows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name} [{product.sku}]
          </MenuItem>
        ));
    }
  };

  const ShipmentsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddShipment}
        >
          Add Shipment
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {!addingShipment ? (
        <DataGrid
          rows={rows}
          columns={columns}
          density='compact'
          hideFooter
          columnVisibilityModel={columnVisibilityModel}
          processRowUpdate={processRowUpdate}
          loading={loading}
          onProcessRowUpdateError={(error) => console.error('Row update error:', error)}
          slots={{
            toolbar: showToolbar ? ShipmentsToolbar : (customToolbar ? customToolbar : null),
          }}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-row': {
              color: 'black',
            },
          }}
        />
      ) : (
        <>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'left', alignItems: 'top' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'top', p: '1rem' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <LabeledDropdown
                  label="From Partner"
                  sx={{ my: '0.25rem', width: '50%' }}
                  options={integrations.filter((integration) => !['HQ', '3PL'].includes(integration.integration_type.category)).map(row => ({ value: row.id, label: row.name }))}

                  value={selectedPartner}
                  onChange={(e) => {
                    setSelectedPO('');
                    setSelectedPartnerTo('');
                    setSkuList([]);
                    setOrderType(integrations.find(row => row.id === e.target.value).integration_type.category === 'Supplier' ? 'material' : 'product');
                    setSelectedPartner(e.target.value);
                  }}
                />
                <LabeledDropdown
                  label="To Partner"
                  sx={{ my: '0.5rem', width: '50%' }}
                  options={
                    (integrations.find((integration) => integration.id === selectedPartner)?.integration_type.category === 'Supplier') ?
                      integrations.filter((integration) => integration.integration_type.category === 'Manufacturer').map(row => ({ value: row.id, label: row.name })) :
                      integrations.filter((integration) => integration.integration_type.category === '3PL').map(row => ({ value: row.id, label: row.name }))
                  }
                  value={selectedPartnerTo}
                  onChange={(e) => setSelectedPartnerTo(e.target.value)}
                />
              </Box>
              {selectedPartner && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                  <FormControl sx={{ width: '50%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isExistingPO} onChange={() => setIsExistingPO(!isExistingPO)} />
                      }
                      label="Shipment is for existing PO"
                    />
                  </FormControl>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                {isExistingPO ? (
                  <Select
                    onOpen={() => handleLoadOptions(selectedPartner)}
                    value={selectedPO}
                    onChange={(e) => {
                      setSelectedPartnerTo(orderDetails.find((order) => order.order_name === e.target.value).integration_id_to);
                      const orders = orderDetails.filter((order) => order.order_name === e.target.value);
                      if (orders) {
                        setSkuList(orders.map(element => ({
                          material_order_id: element.id,
                          product_order_id: element.id,
                          material_id: element.material_id,
                          product_id: element.product_id,
                          unit_of_measure: element.unit_of_measure,
                          qty: element.qty,
                          unit_price: element.unit_price
                        })));
                      }
                      setSelectedPO(e.target.value)
                    }}
                    size="small"
                    sx={{ width: '50%' }}
                    renderValue={(value) => value || 'Select Order'}
                  >
                    {loadingOptions ? (
                      <MenuItem disabled>
                        <CircularProgress size={20} />
                      </MenuItem>
                    ) : (
                      options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                ) : (
                  <CustomTextField
                    label="Order Name"
                    required={false}
                    size='small'
                    variant="outlined"
                    sx={{ my: '0.5rem', width: '50%' }}
                    fullWidth
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                )}
                <CustomTextField
                  label="Carrier"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '50%' }}
                  fullWidth
                  value={carrierName}
                  onChange={(e) => setCarrierName(e.target.value)}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <CustomTextField
                  label="BOL Number"
                  type="number"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '50%' }}
                  fullWidth
                  value={bolNumber}
                  onChange={(e) => setBolNumber(e.target.value)}
                />
                <CustomTextField
                  label="Tracking ID"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '50%' }}
                  fullWidth
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
              </Box>
              <List sx={{ maxHeight: '30vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
                {skuList.map((item, index) => (
                  <ListItem key={index} sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
                    <CustomTextField
                      label="WRO Name"
                      value={item.warehouse_order}
                      sx={{ mr: '5px', width: '40%' }}
                      onChange={(e) => handleSkuInputChange(index, 'warehouse_order', e.target.value)}
                    />
                    <FormControl size='small' fullWidth sx={{ mr: '5px', width: '50%' }}>
                      <InputLabel id={item.id}>Item</InputLabel>
                      <Select
                        labelId={item.id}
                        id={item.id}
                        key={item.id}
                        label="Item"
                        value={item[`${orderType}_id`]}
                        onChange={(e) => handleSkuInputChange(index, `${orderType}_id`, e.target.value)}
                      >
                        {renderOptions()}
                      </Select>
                    </FormControl>
                    <CustomTextField
                      label="Lot"
                      required={false}
                      value={item.lot_number}
                      sx={{ mr: '5px', width: '40%' }}
                      onChange={(e) => handleSkuInputChange(index, 'lot_number', e.target.value)}
                    />
                    <CustomTextField
                      label="Expiration Date"
                      required={false}
                      value={item.expiration_date}
                      sx={{ mr: '5px', width: '40%' }}
                      onChange={(e) => handleSkuInputChange(index, 'expiration_date_raw', e.target.value)}
                    />
                    <CustomTextField
                      label={orderType === 'material' ? `Quantity (${item.unit_of_measure})` : 'Quantity'}
                      type='number'
                      value={item.qty}
                      sx={{ mr: '5px', width: '30%' }}
                      onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
                    />
                    <CustomTextField
                      label="Unit ($)"
                      type='number'
                      required={false}
                      value={item.unit_price}
                      sx={{ mr: '5px', width: '30%' }}
                      onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
                    />
                    <IconButton onClick={() => handleRemoveItem(index)}>
                      <DeleteOutlineIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', my: '0.5rem' }}>
                <Button
                  color="primary"
                  variant='outlined'
                  disabled={isExistingPO}
                  sx={{ width: '20%' }}
                  startIcon={<AddIcon />}
                  onClick={handleAddItem}
                >
                  Add Item
                </Button>
                <Button
                  color="primary"
                  variant='outlined'
                  disabled={skuList.length === 0}
                  sx={{ width: '20%' }}
                  startIcon={<SaveOutlinedIcon />}
                  onClick={handleSaveShipment}
                >
                  Save Shipment
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
