import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import {
  fetchDocuments,
  fetchDocumentContent,
  refreshDocuments,
  createDocument,
  updateDocument,
  deleteDocument,
  fetchCertifications
} from '../../api/documents.js';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PDFViewer from '../../components/PDFViewer.js';


const BackButton = ({ setBlobUrl, setParsedDocument, buttonText = "Back to Table", ...props }) => {
  return (
    <Button
      color="inherit"
      variant="outlined"
      sx={{ width: '35%', height: '2.5rem', mt: '1rem' }}
      startIcon={<ArrowBackIcon />}
      onClick={() => {
        if (setBlobUrl) setBlobUrl(null);
      }}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

const docTypes = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Receiving Instructions', label: 'Receiving Instructions' },
  { value: 'Receiving Order', label: 'Receiving Order' },
  { value: 'Certifications', label: 'Certifications' },
  { value: 'Bill of Lading', label: 'Bill of Lading' },
  { value: 'Upload', label: 'Upload' },
];

export default function DocumentDatagrid({
  rows,
  setRows,
  materialsRows,
  productsRows,
  setMaterialsRows,
  setProductsRows,
  integration,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  isDialogView = true,
  setCertificationBadges,
  height = '90vh',
  loading = null,
}) {
  const [selectedDocumentType, setSelectedDocumentType] = useState("All Documents");
  const [loadingRows, setLoadingRows] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const columnVisibilityModel = { id: false, integration_name: isDialogView ? false : true };
  const fileInputRef = useRef(null);


  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      uploadDocument(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDFs are allowed.');
    }
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const response = await createDocument(integration.id, file.name, b64encodedString);
        setRows([...rows, response]);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      }
    };
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    try {
      let data;
      if (row.msg_id === 'upload') {
        data = await fetchDocumentContent(row.msg_id + '/' + row.integration_id, row.document_name);
      }
      else {
        data = await fetchDocumentContent(row.msg_id, row.document_name);
      }
      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      setBlobUrl(blob);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleRefreshDocuments = async () => {
    setLoadingRows(true);
    try {
      await refreshDocuments(integration.id);
      setSnackbarOpen(true);
      setSnackbarMessage("Refresh triggered. Check back in a minute.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
    setLoadingRows(false);
  };



  const handleDeleteDocument = async (id) => {
    try {
      await deleteDocument(id);
      setRows(rows.filter((r) => r.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  }

  const handleDocumentChange = async (event, row, changedVal) => {
    try {
      const updatedValue = event.target.value;
      let updatedRow = { ...row };
      if (changedVal === 'category') {
        updatedRow = { ...updatedRow, category: updatedValue };
      }
      if (changedVal === 'order_name') {
        updatedRow = { ...updatedRow, order_name: updatedValue };
      }
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_id: updatedValue };
      }
      if (changedVal === 'is_published') {
        updatedRow = { ...updatedRow, is_published: updatedValue };
      }
      const data = await updateDocument(updatedRow);
      updatedRow.id = data.id;
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_name: integrations.find((integration) => integration.id === updatedValue)?.name };
      }
      setRows(rows.map((r) => (r.id === row.id ? updatedRow : r)));
      if (isDialogView && changedVal === 'is_published') {
        const badges = await fetchCertifications(integration.id);
        setCertificationBadges(badges);
      }
      setSnackbarOpen(true);
      setSnackbarMessage("Updated.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handleCategoryFilter = async (event) => {
    setSelectedDocumentType(event);
    let data;
    if (isDialogView) {
      data = await fetchDocuments(integration.id, event === 'All Documents' ? null : event);
    } else {
      data = await fetchDocuments(null, event === 'All Documents' ? null : event);
    }
    setRows(data);
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 80, headerClassName: 'super-app-theme--header' },
    { field: 'source', headerName: 'Data Source', flex: 0.3, headerClassName: 'super-app-theme--header' },
    {
      field: 'integration_name',
      headerName: 'Partner',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.row.integration_id === integrations.find(integration => integration.integration_type.category === "HQ")?.id) {
          return (
            <Select
              value={params.row.integration_id}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "integration_id")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map(integration => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.integration_name;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.75,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.row.source !== 'Email') {
          return (
            <Select
              value={params.row.category}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "category")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {docTypes.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.category;
      },
    },
    {
      field: 'document_name',
      headerName: 'Document',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const documentName = params.row.document_name;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
            <span>{documentName}</span>
            <Tooltip title="View Document" id={`${documentName}-view`}>
              <IconButton onClick={() => handleFetchDocument(params.row)}>
                <SearchOutlinedIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    { field: 'edited_by', headerName: 'Synced By', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'msg_timestamp', headerName: 'Email Date', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'last_updated', headerName: 'Updated', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const icons = [];
        if (params.row.category === 'Certifications') {
          icons.push(
            <Tooltip key="certifications-icon" title={params.row.is_published ? 'Unpublish Certification' : "Publish Certification"} id={`${params.row.document_name}-view`}>
              <IconButton
                key="certifications-icon"
                onClick={() => handleDocumentChange({ target: { value: !params.row.is_published } }, params.row, "is_published")}
              >
                {params.row.is_published ? <VerifiedIcon sx={{ color: 'black' }} /> : <VerifiedOutlinedIcon sx={{ color: 'black' }} />}
              </IconButton>
            </Tooltip>
          );
        }
        if (params.row.source === 'Email') {
          icons.push(
            <IconButton
              key="email-icon"
              component="a"
              href={`https://mail.google.com/mail/u/0/#inbox/${params.row.msg_id}`}
              target="_blank"
            >
              <EmailOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        } else {
          icons.push(
            <IconButton
              key="delete-icon"
              onClick={() => handleDeleteDocument(params.row.id)}
            >
              <DeleteOutlineOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        }
        return <>{icons}</>;
      },
      headerClassName: 'super-app-theme--header'
    }
  ];


  const GridToolbar = () => {
    return (
      <>
        {!loadingRows ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <GridToolbarContainer>
                <Button
                  color="inherit"
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={handleUploadClick}
                >
                  Add Document
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </GridToolbarContainer>
              {isDialogView && (
                <GridToolbarContainer>
                  <Button
                    color="inherit"
                    variant='outlined'
                    startIcon={<SyncIcon />}
                    onClick={handleRefreshDocuments}
                  >
                    Sync Email
                  </Button>
                </GridToolbarContainer>
              )}
              <GridToolbarContainer>
                <Select
                  value={selectedDocumentType}
                  size='small'
                  variant='standard'
                  sx={{ ml: '1rem', width: '250px' }}
                  onChange={(e) => handleCategoryFilter(e.target.value)}
                >
                  <MenuItem key='all' value='All Documents'>
                    All Documents
                  </MenuItem>
                  {docTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </GridToolbarContainer>
            </Box>
          </>
        ) : (
          <CircularProgress size={24} sx={{ ml: '0.5rem', mt: '0.5rem' }} />
        )
        }
      </>
    );
  };

  const ViewerToolbar = () => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <GridToolbarContainer>
            <BackButton
              setBlobUrl={setBlobUrl}
              sx={{}}
            />
          </GridToolbarContainer>
        </Box>
      </>
    );
  };


  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: { height }, bgcolor: 'white', borderRadius: '8px' }}>
        {!blobUrl ? (
          <Box sx={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density='compact'
              columnVisibilityModel={columnVisibilityModel}
              hideFooter
              loading={loading}
              slots={{
                toolbar: GridToolbar,
              }}
              sx={{
                backgroundColor: 'white',
                '& .MuiDataGrid-row': {
                  color: 'black',
                },
              }}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '50%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density='compact'
                columnVisibilityModel={{
                  ...columnVisibilityModel,
                  source: false,
                  edited_by: false,
                  last_updated: false,
                }}
                hideFooter
                slots={{
                  toolbar: ViewerToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" width="50%" height="100%">
              <PDFViewer pdfBlob={blobUrl} />
            </Box>
          </>
        )}
      </Box >
    </>
  );
}
