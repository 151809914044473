import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  Button,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CustomTextField from './CustomTextField';
import { createIntegration, syncShopifyLocations } from '../api/integrations';
import { fetchProducts } from '../api/products';
import { axiosInstance, checkToken } from '../api/utils';
import SearchIcon from '@mui/icons-material/Search';

const ShopifySetup = ({
  setProductsRows,
  shopifyLocations,
  setShopifyLocations,
  integrationTypes,
  hqIntegration,
  integrations,
  setIntegrations,
  dataSources,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) => {
  const [partnerName, setPartnerName] = useState('');
  const [nativeId, setNativeId] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactTitle, setContactTitle] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [showStreetAddress, setShowStreetAddress] = useState(false);
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);


  const handleLocationLookup = async (locationInput) => {
    try {
      const token = await checkToken();
      const response = await axiosInstance(token).post('/location_lookup/', { location: locationInput || locationSearch });
      setStreetAddress(response.data.body[0].formatted_address);
      setBillingAddress(response.data.body[0].formatted_address);
      setLat(response.data.body[0].geometry.location.lat);
      setLong(response.data.body[0].geometry.location.lng);
      setShowStreetAddress(true);
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsLoading(true);
      await handleLocationLookup();
      setIsLoading(false);
    }
  };

  const handleSearchPress = async () => {
    setIsLoading(true);
    await handleLocationLookup();
    setIsLoading(false);
  };

  const handleSetUpForm = async (location) => {
    setNativeId(location.id)
    setPartnerName(location.name)
    if (location.address1) {
      setLocationSearch(`${location.address1} ${location.city} ${location.country}`);
      handleLocationLookup(`${location.address1} ${location.city} ${location.country}`);
    } else {
      setLocationSearch('');
      setStreetAddress('');
      setBillingAddress('');
      setShowStreetAddress(false);
    }
  };

  const handleValidate = () => {
    if (partnerName === '' || contactName === '' || streetAddress === '') {
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage("Please complete remaining form items");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (handleValidate()) {
        const newIntegration = {
          integration_type_id: integrationTypes.find(row => row.category === '3PL').id,
          source_id: dataSources.find(row => row.type === 'Shopify').id,
          native_id: String(nativeId),
          name: partnerName,
          contact_name: contactName,
          contact_title: contactTitle || '',
          contact_email: contactEmail,
          street_address: streetAddress,
          billing_address: billingAddress,
          config: {},
          coordinates: { long, lat }
        };

        const integrationSuccess = await createIntegration(newIntegration);
        setIntegrations((prevIntegrations) => [...prevIntegrations, integrationSuccess]);
        setShopifyLocations((prevLocations) =>
          prevLocations.filter((row) => row.id !== nativeId)
        );
        await syncShopifyLocations(
          newIntegration.native_id,
          hqIntegration.id,
          integrationSuccess.id
        );
        const productsData = await fetchProducts(hqIntegration.id);
        setProductsRows(productsData);
        setPartnerName('');
        setContactTitle('');
        setContactName('');
        setContactEmail('');
        setLocationSearch('');
        setStreetAddress('');
        setBillingAddress('');
        setShowStreetAddress(false);
        setSnackbarOpen(true);
        setSnackbarAlert("success");
        setSnackbarMessage("Partner created and inventory synced");
      }
    } catch (error) {
      console.error('Error creating integration:', error);
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage(error.response.data.detail);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        open={shopifyLocations.length > 0}
        onClose={() => setShopifyLocations([])}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Found {shopifyLocations.length} locations in Shopify</DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'row'}>
            <Box sx={{ width: '45%' }}>
              <List sx={{ maxHeight: '50vh', overflowY: 'auto', mt: 0 }}>
                {shopifyLocations.map(location => (
                  <ListItem key={location.id}>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Box>
                        <Typography>
                          <b>{location.name}</b>
                          <br />
                          {location.address1 && `${location.address1}, ${location.city} ${location.country}`}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Button variant="outlined" onClick={() => handleSetUpForm(location)}>Sync To Trinity</Button>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box flexGrow={1} sx={{ p: 0.5, mt: 2 }}>
              {partnerName && (
                <Box>
                  <CustomTextField
                    label="Partner Name"
                    value={partnerName}
                    onChange={(e) => setPartnerName(e.target.value)}
                  />
                  <CustomTextField
                    label="Contact Name"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                  <CustomTextField
                    label="Contact Title"
                    value={contactTitle}
                    onChange={(e) => setContactTitle(e.target.value)}
                    required={false}
                  />
                  <CustomTextField
                    label="Contact Email"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                  {!isLoading ? (
                    <>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ width: '80%' }}>
                          <CustomTextField
                            label="Search Address"
                            value={locationSearch}
                            onChange={(e) => setLocationSearch(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                        </Box>
                        <Box sx={{ ml: '5px' }}>
                          <Button sx={{ m: '0.3rem' }} variant='outlined' startIcon={<SearchIcon />} onClick={handleSearchPress}>Search</Button>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size={24} style={{ color: 'black', marginTop: '10px' }} />
                    </Box>
                  )}
                  {showStreetAddress && !isLoading && (
                    <>
                      <Typography sx={{ m: '0.5rem' }}>
                        Formatted Street Address: <b>{streetAddress}</b>
                      </Typography>
                      <FormControlLabel
                        sx={{ ml: '0.2rem', mb: '0.5rem' }}
                        control={
                          <Checkbox
                            checked={isBillingSameAsShipping}
                            onChange={(event) => setIsBillingSameAsShipping(event.target.checked)}
                            name="billingSameAsShipping"
                          />
                        }
                        label="Billing is same as shipping"
                      />
                      {!isBillingSameAsShipping && (
                        <CustomTextField
                          label="Billing Address"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                        />
                      )}
                    </>
                  )}
                  {!isLoading && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
                      <Button
                        variant='outlined'
                        onClick={handleSubmit}
                      >
                        Add Partner
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShopifyLocations([])}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopifySetup;
