import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import quickbooks_logo from '../assets/icons8-quickbooks-32.png';

const QuickbooksLink = () => {

  const clientId = process.env.REACT_APP_QB_CLIENT;
  const redirectUri = 'https://' + process.env.REACT_APP_REDIRECT_URI;
  const authUri = 'https://appcenter.intuit.com/connect/oauth2';
  const scope = 'com.intuit.quickbooks.accounting';

  const handleOAuthLogin = () => {
    const queryParams = new URLSearchParams({
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: scope,
      state: 'random_state_string'
    });
    window.location.href = `${authUri}?${queryParams.toString()}`;
  };


  return (
    <>
      <Card sx={{ width: '350px', height: '150px' }}>
        <CardActionArea sx={{ p: 1, height: '100%' }} onClick={handleOAuthLogin}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={quickbooks_logo} alt="Quickbooks Logo" style={{ width: '35px', height: '35px' }} />
            </Box>
            <Typography variant="body1" color="black">
              QuickBooks
            </Typography>
            <Typography sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link vendors from Quickbooks.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card >
    </>
  )
}

export default QuickbooksLink;