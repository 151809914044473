import { Image, useTheme } from "@aws-amplify/ui-react";
import logo from '../assets/logo.png';

export function Header() {
  const { tokens } = useTheme();

  return (
    <div style={{ textAlign: "center" }}>
      <Image
        alt="logo"
        src={logo}
        padding={tokens.space.small}
        style={{ width: "250px", height: "auto", display: "inline-block" }}
      />
    </div>
  );
}
