import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress
} from '@mui/material';
import trinity_logo from './assets/logo.png';
import axios from 'axios';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { ThemeProvider, CssBaseline } from '@mui/material';
import MainApp from './pages/MainApp';
import theme from './theme/theme';
import './theme/global.css';
import { Header } from "./components/AmplifyHeader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { getLogo } from './api/users';


Amplify.configure(config);

function App({ signOut, user }) {
  const userID = user.userId
  const upoolId = process.env.REACT_APP_AMPLIFY_USER_POOL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const idToken = localStorage.getItem(`CognitoIdentityServiceProvider.${upoolId}.${userID}.idToken`);
  localStorage.setItem('apiToken', idToken);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [logo, setLogo] = React.useState(null);
  const [logoBlob, setLogoBlob] = React.useState(null);
  const [userObj, setUserObj] = React.useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await fetchAuthSession();
        const userData = await axios.get(`${apiUrl}/user/`, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
        setUserObj(userData.data);
        localStorage.setItem('user', user.signInDetails.loginId);
        localStorage.setItem('identityId', session.identityId.toString());
        localStorage.setItem('orgId', userData.data?.org_id?.toString() || '');
      } catch (error) {
        console.error('Error fetching content:', error);
        return;
      }

      try {
        const base64String = await getLogo();
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setLogo(url);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64StringReader = reader.result.split(',')[1];
          setLogoBlob(base64StringReader);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.log('Error downloading file:', error);
      } finally {
        setIsInitialized(true);
      }
    };

    fetchUser();
  }, [user, apiUrl, idToken]);

  if (!isInitialized) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', bgcolor: 'black' }}>
        <img src={trinity_logo} alt="Trinity Logo" style={{ width: '125px' }} />
        <CircularProgress size={20} sx={{ color: 'white', mt: '1rem' }} />
      </Box>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<MainApp user={userObj} setUserObj={setUserObj} logo={logo} logoBlob={logoBlob} setLogo={setLogo} signOut={signOut} />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header: Header,
    }
  },
});