import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, Box, DialogTitle, DialogContent } from '@mui/material';
import { fetchNextOrder } from '../api/orders';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { format } from 'date-fns';


const ActionsDialogView = ({
  open,
  handleClose,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (open) {
      fetchNextOrder()
        .then((response) => {
          setActions(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        });
    }
  }, [open]);

  const actionsColumns = [
    { field: 'partner_name', headerName: 'Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'item_name', headerName: 'Item', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'item_sku', headerName: 'SKU', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'item_type', headerName: 'Item Type', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'next_order_date',
      headerName: 'Order By',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {format(date, 'MM/dd/yy')}
            {params.row.next_order < 7 ? <WarningAmberIcon sx={{ color: 'orange', ml: '0.25rem' }} /> : ''}
          </span>
        );
      },
    },
  ]


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Recommended Actions</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '50vh', maxHeight: '50vh' }}>
            <DataGrid
              rows={actions}
              columns={actionsColumns}
              density='compact'
              loading={loading}
              hideFooter
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default ActionsDialogView;
