// forecast.js

import { axiosInstance, checkToken } from './utils.js'

const fetchForecast = async (name = null, published = null) => {
  try {
    const params = new URLSearchParams();
    if (name) {
      params.append('name', name);
    } else if (published) {
      params.append('published', true);
    }
    const queryString = params.toString();
    const url = `/forecasts/` + (queryString ? `?${queryString}` : '');
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};

const fetchInventoryForecast = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/products_inventory_forecast/');
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


const publishForecast = async (name) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/forecasts/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};

const getMaterialForecastOrders = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/forecasts_materials/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


const deleteForecast = async (name) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/forecasts/${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


const createForecast = async (forecastData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/forecasts/', forecastData);
    return response.data;
  } catch (error) {
    console.error('Error fetching integrations:', error);
    throw error;
  }
};


const fetchForecastOptions = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/product_options/');
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


const fetchProductLimits = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/products_limits/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};

const fetchProductLimitDetails = async (integration_id, product_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/products_limit_details/${integration_id}/${product_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};

const fetchTrend = async (grouping, days_history) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/trends/?group_by=${grouping}&days_history=${days_history}&time_zone=${timeZone}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


export {
  fetchForecast,
  fetchInventoryForecast,
  publishForecast,
  getMaterialForecastOrders,
  deleteForecast,
  createForecast,
  fetchForecastOptions,
  fetchProductLimits,
  fetchProductLimitDetails,
  fetchTrend
};
