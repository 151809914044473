import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleAuthProvider } from './components/GoogleAuthContext';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="284588180773-b685effnt2juf7i3n0c7smpck7kjre1k.apps.googleusercontent.com">
    <GoogleAuthProvider>
      <App />
    </GoogleAuthProvider>
  </GoogleOAuthProvider>
);
