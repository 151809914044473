import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box
} from '@mui/material';
import { createOrders, fetchAllOrders } from '../api/orders';
import { fetchMaterialsInventory, fetchInventory } from '../api/products';
import CustomTextField from './CustomTextField';
import LabeledDropdown from './LabeledDropdown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

const CreatePODialog = ({
  open,
  onClose,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  setRows = null,
  inputIntegration = null,
}) => {
  const [skuList, setSkuList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedPartnerOptions, setSelectedPartnerOptions] = useState([]);
  const [selectedPartnerTo, setSelectedPartnerTo] = useState(inputIntegration || '');
  const [validRowOptions, setValidRowOptions] = useState([]);
  const [poNumber, setPONumber] = useState('');
  const [orderType, setOrderType] = useState('product');

  useEffect(() => {
    if (inputIntegration) {
      setSelectedPartnerTo(inputIntegration.id);
      const partnerToType = integrations.find(row => row.id === inputIntegration.id).integration_type.category
      setSelectedPartnerOptions(
        integrations.filter(row =>
          (partnerToType === 'Manufacturer' ? ['Supplier'] : ['Manufacturer']).includes(row.integration_type.category)
        )
      );
    }
    else {
      setSelectedPartnerOptions(
        integrations.filter(row => !['HQ', '3PL'].includes(row.integration_type.category))
      );
    }
  }, [inputIntegration, integrations]);

  const handleSetPartner = async (event) => {
    const partnerType = integrations.find(row => row.id === event.target.value).integration_type.category
    setSelectedPartner(event.target.value);
    setOrderType(partnerType === 'Supplier' ? 'material' : 'product');
    if (partnerType === 'Supplier') {
      const data = await fetchMaterialsInventory(event.target.value);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.material_id);
      setValidRowOptions(validIds);
    } else {
      const data = await fetchInventory(event.target.value);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.product_id);
      setValidRowOptions(validIds);
    }
  };

  const handleSkuInputChange = (index, field, value) => {
    const newSkuList = [...skuList];
    newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
    if (field === `${orderType}_id` && orderType === "material") {
      newSkuList[index]['unit_of_measure'] = materialsRows.filter(row => row.id === value)[0].purchasing_uom;
    }
    setSkuList(newSkuList);
  };

  const handleRemoveItem = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const handleAddItem = () => {
    if (orderType === 'material') {
      setSkuList(
        [...skuList,
        {
          material_id: materialsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: ''
        }
        ]);
    } else {
      setSkuList(
        [...skuList,
        {
          product_id: productsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_of_measure: selectedPartner.minimum_order_units,
          unit_price: ''
        }
        ]);
    }
  }

  const renderOptions = () => {
    if (orderType === 'material') {
      return materialsRows
        .filter(material => validRowOptions.includes(material.id))
        .map(material => (
          <MenuItem key={material.id} value={material.id}>
            {material.name}
          </MenuItem>
        ));
    } else {
      return productsRows
        .filter(product => validRowOptions.includes(product.id))
        .map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name}
          </MenuItem>
        ));
    }
  };

  const handleValidate = () => {
    if (poNumber.trim() === '') {
      setSnackbarAlert("error")
      setSnackbarMessage("Please name the PO")
      setSnackbarOpen(true)
      return false;
    }

    if (skuList.length === 0) {
      setSnackbarAlert("error")
      setSnackbarMessage("Please add items to the PO")
      setSnackbarOpen(true)
      return false;
    }

    for (const item of skuList) {
      if (!item[`${orderType}_id`] || item.qty === '' || item.unit_of_measure === '' || item.unit_price === '') {
        setSnackbarAlert("error")
        setSnackbarMessage("Please complete all items")
        setSnackbarOpen(true)
        return false;
      }
    }

    return true;
  };

  const handleCreateOrders = async () => {
    if (handleValidate()) {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: selectedPartner,
        integration_id_to: selectedPartnerTo || null,
        order_name: poNumber,
        status: "Draft",
        tax_rate: (item.tax_rate || 0) / 100
      }));
      try {
        await createOrders(updatedSkuList, orderType);
        if (setRows) {
          const data = await fetchAllOrders();
          setRows(data);
        }
        setSnackbarOpen(true);
        setSnackbarMessage("Order created successfully.");
        setSnackbarAlert("success");
        setSkuList([]);
        setPONumber('');
        onClose();
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Create Draft PO</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LabeledDropdown
            label="Partner"
            value={selectedPartner}
            sx={{ my: '0.5rem', width: '49%', mr: '0.5rem' }}
            options={selectedPartnerOptions.map(row => ({ value: row.id, label: row.name }))}
            onChange={handleSetPartner}
          />
          {inputIntegration ? (
            <Typography sx={{ m: '1rem' }}><b>Shipping To: {inputIntegration.name}</b></Typography>
          ) : (
            <LabeledDropdown
              label="Ship To"
              value={selectedPartnerTo}
              sx={{ my: '0.5rem', width: '49%' }}
              options={integrations.filter((row) =>
                integrations.find((r) => r.id === selectedPartner)?.integration_type.category === 'Manufacturer' ? row.integration_type.category === '3PL' : row.integration_type.category === 'Manufacturer'
              ).map((row) => ({ value: row.id, label: row.name }))}
              onChange={(event) => setSelectedPartnerTo(event.target.value)}
            />
          )}
        </Box>
        {selectedPartner && (
          <Typography color={"error"} sx={{ 'm': '0.25rem' }}>
            Minimum Order Quantity: {integrations.filter(row => row.id === selectedPartner)[0].minimum_order_qty} {integrations.filter(row => row.id === selectedPartner)[0].minimum_order_units}
          </Typography>
        )}
        <CustomTextField
          label="PO Number"
          size='small'
          variant="outlined"
          sx={{ my: '0.25rem', width: '49%' }}
          fullWidth
          value={poNumber}
          onChange={(e) => setPONumber(e.target.value)}
        />
        <List sx={{ maxHeight: '40vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
          {skuList.map((item, index) => (
            <ListItem key={index} sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
              <FormControl size='small' fullWidth sx={{ mr: '5px', width: '60%' }}>
                <InputLabel id={item.id}>Item</InputLabel>
                <Select
                  labelId={item.id}
                  id={item.id}
                  key={item.id}
                  label="Item"
                  value={item[`${orderType}_id`]}
                  onChange={(e) => handleSkuInputChange(index, `${orderType}_id`, e.target.value)}
                >
                  {renderOptions()}
                </Select>
              </FormControl>
              <CustomTextField
                label={orderType === 'material' ? `Quantity (${item.unit_of_measure})` : 'Quantity'}
                type='number'
                value={item.qty}
                sx={{ mr: '5px', width: '50%' }}
                onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
              />
              <CustomTextField
                label="Unit Price ($)"
                type='number'
                value={item.unit_price}
                sx={{ mr: '5px', width: '50%' }}
                onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
              />
              <CustomTextField
                label="Tax Rate (%)"
                type='number'
                sx={{ width: '50%' }}
                value={item.tax_rate}
                onChange={(e) => handleSkuInputChange(index, 'tax_rate', e.target.value)}
              />
              <IconButton onClick={() => handleRemoveItem(index)}>
                <DeleteOutlineIcon sx={{ color: 'black' }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          color="primary"
          variant='outlined'
          disabled={poNumber === ''}
          sx={{ my: '0.25rem' }}
          startIcon={<AddIcon />}
          onClick={handleAddItem}
        >
          Add Item
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateOrders} color="primary">
          Create Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePODialog;
