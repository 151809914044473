import React, { useState, useEffect } from 'react';
import IntegrationDialogView from './IntegrationDialogView';
import IntransitDialogView from './IntransitDialogView';
import ActionsDialogView from './ActionsDialogView';
import { fetchIntegrationRoutes } from '../api/integrations';
import { GoogleMap, LoadScript, Marker, Polyline, Data, MarkerClusterer } from '@react-google-maps/api';
import hq from '../assets/hq.svg';
import mfg from '../assets/factory.svg';
import warehouse from '../assets/warehouse.svg';
import supplier from '../assets/logistics.svg';
import truck from '../assets/truck.svg';
import actions from '../assets/actions.svg';
import cluster from '../assets/cluster.svg';
import countries_outline from '../assets/countries.geojson';
import { Typography } from '@mui/material';

const options = {
  mapId: process.env.REACT_APP_MAP_ID,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: true,
  minZoom: 2.1,
  scaleControl: true,
  restriction: {
    latLngBounds: {
      north: 85.00000,
      south: -85.00000,
      east: 180,
      west: -180
    },
    strictBounds: true,
  },
  rotateControl: false,
  trafficLayer: false,
  transitLayer: false,
  disableDefaultUI: true,
};

const MapComponent = ({
  logoBlob,
  integrations,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  setIntegrations,
  showRoutes,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  dataSources
}) => {
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [transitDialogOpen, setTransitDialogOpen] = useState(false);
  const [actionsDialogOpen, setActionsDialogOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 38.716, lng: -98.356 });

  useEffect(() => {
    const fetchData = async () => {
      if (showRoutes) {
        try {
          const routesResponse = await fetchIntegrationRoutes();
          setRoutes(routesResponse);
        } catch (error) {
          console.error('Error fetching integration routes:', error);
        }
      }
    };

    fetchData();
  }, [showRoutes]);

  const handleMarkerClick = (id, coordinates) => {
    setSelectedIntegrationId(id);
    setMapCenter({ lat: coordinates.lat, lng: coordinates.long });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API}
        onLoad={handleMapLoad}
        onError={(error) => console.error('Error loading Google Maps script:', error)}
      >
        {mapLoaded && (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
              style={{
                position: 'absolute',
                bottom: 85,
                left: 10,
                borderRadius: '15px',
                padding: '1rem',
                backgroundColor: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                zIndex: 10,
                border: '1px solid white'
              }}
            >
              <img
                src={actions}
                onClick={() => setActionsDialogOpen(true)}
                alt="intransitIcon"
                style={{
                  cursor: 'pointer',
                  width: '50px',
                  height: '50px',
                }}
              />
              <Typography sx={{ color: 'white', fontFamily: 'MontHeavy', m: 0 }}>Actions</Typography>
              <img
                src={truck}
                onClick={() => setTransitDialogOpen(true)}
                alt="intransitIcon"
                style={{
                  cursor: 'pointer',
                  width: '50px',
                  height: '50px',
                }}
              />
              <Typography sx={{ color: 'white', fontFamily: 'MontHeavy', m: 0 }}>In Transit</Typography>
            </div>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
              }}
              center={mapCenter}
              zoom={5}
              options={options}
            >
              <MarkerClusterer
                options={{
                  styles: [
                    {
                      url: cluster,
                      textColor: 'black',
                      height: 50,
                      width: 50,
                      fontFamily: 'MontHeavy',
                      textSize: 18,
                    },
                  ],
                  calculator: (markers) => {
                    const count = markers.length;
                    return {
                      text: `${count}`,
                      index: count < 10 ? 0 : count < 100 ? 1 : 2,
                    };
                  },
                }}
              >
                {(clusterer) => (
                  <div>
                    {integrations.map(({ id, name, coordinates, integration_type }) => (
                      <Marker
                        key={id}
                        position={{ lat: coordinates.lat, lng: coordinates.long }}
                        clusterer={clusterer}
                        label={{
                          text: name,
                          color: 'white',
                          fontSize: '14px',
                          className: 'marker-label',
                          fontFamily: 'MontHeavy',
                        }}
                        icon={{
                          url: integration_type.category === 'HQ' ? hq : integration_type.category === '3PL' ? warehouse : integration_type.category === 'Supplier' ? supplier : mfg,
                          scaledSize: new window.google.maps.Size(50, 50),
                          anchor: new window.google.maps.Point(25, 25),
                          labelOrigin: new window.google.maps.Point(25, -5),
                        }}
                        onClick={() => handleMarkerClick(id, coordinates)}
                      />
                    ))}
                  </div>
                )}
              </MarkerClusterer>
              {showRoutes && routes !== null && routes.map((route, index) => (
                <Polyline
                  key={index}
                  path={[
                    { lng: route.start_integration.coordinates.long, lat: route.start_integration.coordinates.lat },
                    { lng: route.end_integration.coordinates.long, lat: route.end_integration.coordinates.lat },
                  ]}
                  options={{
                    strokeColor: 'red',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                  }}
                />
              ))}
              <Data
                key="countries_layer"
                onLoad={(dataLayer) => {
                  dataLayer.loadGeoJson(countries_outline);
                  dataLayer.setStyle({
                    strokeColor: '#757575',
                    strokeWeight: 1,
                    fillOpacity: 0
                  });
                }}
              />
            </GoogleMap>
          </div>
        )}
      </LoadScript>
      {dialogOpen && (
        <IntegrationDialogView
          logoBlob={logoBlob}
          productsRows={productsRows}
          setProductsRows={setProductsRows}
          materialsRows={materialsRows}
          setMaterialsRows={setMaterialsRows}
          open={dialogOpen}
          integration={integrations.find(integration => integration.id === selectedIntegrationId)}
          integrations={integrations}
          setIntegrations={setIntegrations}
          onClose={handleCloseDialog}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
          dataSources={dataSources}
        />
      )}
      {transitDialogOpen && (
        <IntransitDialogView
          open={transitDialogOpen}
          productsRows={productsRows}
          materialsRows={materialsRows}
          handleClose={() => setTransitDialogOpen(false)}
          integrations={integrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      {actionsDialogOpen && (
        <ActionsDialogView
          open={actionsDialogOpen}
          handleClose={() => setActionsDialogOpen(false)}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
    </>
  );
};

export default MapComponent;
