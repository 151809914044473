import React from 'react';
import {
  Grid,
  Box,
  Backdrop,
  CircularProgress
} from '@mui/material';
import MapComponent from '../components/MapComponent';
import TopBar from '../components/TopBar';
import VisualizeTable from '../components/VisualizeTable';
import Trends from '../components/Trends';
import Settings from '../components/Settings';
import { fetchIntegrations, fetchIntegrationTypes, createQuickbooksToken, createShopifyToken } from '../api/integrations';
import SnackbarAlert from '../components/SnackbarAlert';
import { fetchProducts, fetchMaterials } from '../api/products';
import { fetchNotifications, fetchSources } from '../api/notifications';


const MainApp = ({
  user,
  setUserObj,
  logo,
  logoBlob,
  setLogo,
  signOut
}) => {
  const [currentView, setCurrentView] = React.useState('map');
  const [integrations, setIntegrations] = React.useState([]);
  const [integrationTypes, setIntegrationTypes] = React.useState([]);
  const [hqIntegration, setHqIntegration] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [dataSources, setDataSources] = React.useState([]);
  const [showRoutes, setShowRoutes] = React.useState(false);
  const [selectedDatagrid, setSelectedDatagrid] = React.useState("Integrations");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarAlert, setSnackbarAlert] = React.useState("");
  const [productsRows, setProductsRows] = React.useState([]);
  const [materialsRows, setMaterialsRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchIntegrationsData = async () => {
      try {
        const integrations_data = await fetchIntegrations();
        const integration_types_data = await fetchIntegrationTypes();
        setIntegrations(integrations_data);
        setIntegrationTypes(integration_types_data);
        setHqIntegration(integrations_data.find(integration => integration.integration_type.type === 'HQ'))
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    const fetchNotificationsData = async () => {
      try {
        const notifications_data = await fetchNotifications();
        setNotifications(notifications_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    const fetchDataSourcesData = async () => {
      try {
        const sources_data = await fetchSources();
        setDataSources(sources_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    const checkUrlParams = async () => {
      setIsLoading(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const shop = urlParams.get('shop');
        const code = urlParams.get('code');
        const realm_id = urlParams.get('realmId');
        if (realm_id && code) {
          await createQuickbooksToken(code, realm_id);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('QuickBooks linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
          setCurrentView("settings");
        }
        else if (shop && code) {
          await createShopifyToken(shop, code);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('Shopify linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
          setCurrentView("settings");
        }
      } catch (error) {
        console.error('Error exchanging token:', error);
        setSnackbarOpen(true);
        setSnackbarMessage('Failed to exchange token');
        setSnackbarAlert('error');
      }
      setIsLoading(false);
    }

    checkUrlParams();
    fetchIntegrationsData();
    fetchNotificationsData();
    fetchDataSourcesData();
  }, []);

  React.useEffect(() => {
    const fetchMaterialsData = async () => {
      try {
        const data = await fetchMaterials(hqIntegration.id);
        setMaterialsRows(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    const fetchProductsData = async () => {
      try {
        const data = await fetchProducts(hqIntegration.id);
        setProductsRows(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };


    if (hqIntegration) {
      fetchMaterialsData();
      fetchProductsData();
    }
  }, [hqIntegration]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: 'black',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12}>
          <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: '0.5rem', mx: '0.5rem' }}>
              <TopBar
                logo={logo}
                logoBlob={logoBlob}
                currentView={currentView}
                setCurrentView={setCurrentView}
                notifications={notifications}
                hqIntegration={integrations.find(item => item.integration_type.type === 'HQ')}
                integrations={integrations}
                materialsRows={materialsRows}
                productsRows={productsRows}
                setIntegrations={setIntegrations}
                integrationTypes={integrationTypes}
                showRoutes={showRoutes}
                setShowRoutes={setShowRoutes}
                selectedDatagrid={selectedDatagrid}
                setSelectedDatagrid={setSelectedDatagrid}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarAlert={setSnackbarAlert}
                signOut={signOut}
              />
            </Box>
            <Box
              sx={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                overflow: 'hidden',
                p: '0.2rem'
              }}
            >
              {currentView === 'map' ? (
                <MapComponent
                  logoBlob={logoBlob}
                  productsRows={productsRows}
                  setProductsRows={setProductsRows}
                  materialsRows={materialsRows}
                  setMaterialsRows={setMaterialsRows}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  showRoutes={showRoutes}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  dataSources={dataSources}
                />
              ) : currentView === 'dashboard' ? (
                <Trends />
              ) : currentView === 'table' ? (
                <VisualizeTable
                  logoBlob={logoBlob}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  integrationTypes={integrationTypes}
                  productsRows={productsRows}
                  setProductsRows={setProductsRows}
                  materialsRows={materialsRows}
                  setMaterialsRows={setMaterialsRows}
                  selectedDatagrid={selectedDatagrid}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  dataSources={dataSources}
                />
              ) : currentView === 'settings' ? (
                <Settings
                  user={user}
                  setUserObj={setUserObj}
                  hqIntegration={integrations.find(item => item.integration_type.type === 'HQ')}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  integrationTypes={integrationTypes}
                  logo={logo}
                  setLogo={setLogo}
                  dataSources={dataSources}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  setProductsRows={setProductsRows}
                  setIsLoading={setIsLoading}
                  setDataSources={setDataSources}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default MainApp;
