import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import IntegrationDialogView from './IntegrationDialogView';
import IntegrationMainDatagrid from './datagrid/IntegrationMainDatagrid';
import OrdersMainDatagrid from './datagrid/OrdersMainDatagrid';
import InventoryMainDatagrid from './datagrid/InventoryMainDatagrid';
import ProductRecipeTable from './datagrid/ProductsDialogDatagrid';
import MaterialsDialogDatagrid from './datagrid/MaterialsDialogDatagrid';
import DocumentDatagrid from './datagrid/DocumentDatagrid';
import ShipmentsDatagrid from './datagrid/ShipmentsDatagrid';
import { fetchShipments } from '../api/shipments';
import { fetchAllOrders } from '../api/orders';
import { fetchAllInventory, fetchAllMaterialInventory } from '../api/products';
import { fetchDocuments } from '../api/documents';

const VisualizeTable = ({
  logoBlob,
  integrations,
  setIntegrations,
  integrationTypes,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  selectedDatagrid,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  dataSources
}) => {
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [shipmentRows, setShipmentRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState('All Orders');

  useEffect(() => {
    if (selectedDatagrid === 'Orders') {
      const fetchOrdersData = async (status) => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllOrders(status);
          setRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }  finally {
          setLoading(false);
        }
      };

      if (selectedStatus !== 'All Orders') {
        fetchOrdersData(selectedStatus);
      } else {
        fetchOrdersData();
      }
    } else if (selectedDatagrid === 'Products Inventory') {
      const fetchInventoryData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllInventory();
          const shipment_data = await fetchShipments("Manufacturer");
          setRows(data);
          setShipmentRows(shipment_data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        }  finally {
          setLoading(false);
        }
      };
      fetchInventoryData();
    } else if (selectedDatagrid === 'Materials Inventory') {
      const fetchMaterialInventoryData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllMaterialInventory();
          const shipment_data = await fetchShipments("Supplier");
          setShipmentRows(shipment_data);
          setRows(data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        }  finally {
          setLoading(false);
        }
      };
      fetchMaterialInventoryData();
    } else if (selectedDatagrid === 'Documents') {
      const fetchDocumentData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchDocuments();
          setRows(data);
        } catch (error) {
          console.error('Error fetching documents:', error);
        }  finally {
          setLoading(false);
        }
      };
      fetchDocumentData();
    }
  }, [selectedDatagrid, selectedStatus]);


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  return (
    <>
      {selectedDatagrid === 'Integrations' ? (
        <Box sx={{
          mt: '0.5rem',
          height: '100%',
          width: '100%'
        }}>
          <IntegrationMainDatagrid
            integrations={integrations}
            setIntegrations={setIntegrations}
            integrationTypes={integrationTypes}
            setSelectedIntegrationId={setSelectedIntegrationId}
            setDialogOpen={setDialogOpen}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            loading={loading}
          />
          {dialogOpen && (
            <IntegrationDialogView
              open={dialogOpen}
              productsRows={productsRows}
              setProductsRows={setProductsRows}
              materialsRows={materialsRows}
              setMaterialsRows={setMaterialsRows}
              logoBlob={logoBlob}
              integration={integrations.find(integration => integration.id === selectedIntegrationId)}
              integrations={integrations}
              setIntegrations={setIntegrations}
              onClose={handleCloseDialog}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              dataSources={dataSources}
            />
          )}
        </Box>
      ) : selectedDatagrid === 'Recipes' ? (
        <Box sx={{
          mt: '0.5rem',
          height: '100%',
          width: '100%',
        }}>
          <ProductRecipeTable
            integrationId={integrations.find(integration => integration.integration_type.category === 'HQ')?.id}
            productsRows={productsRows}
            setProductsRows={setProductsRows}
            materialsRows={materialsRows}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            height='50%'
            loading={loading}
          />
          <MaterialsDialogDatagrid
            integrationId={integrations.find(integration => integration.integration_type.category === 'HQ')?.id}
            productsRows={productsRows}
            materialsRows={materialsRows}
            setMaterialsRows={setMaterialsRows}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            height='43vh'
            marginTop={true}
            loading={loading}
          />
        </Box>
      ) : selectedDatagrid === 'Orders' ? (
        <Box sx={{
          m: '0.5rem',
          height: '100%',
          width: '100%'
        }}>
          <OrdersMainDatagrid
            rows={rows}
            setRows={setRows}
            materialsRows={materialsRows}
            productsRows={productsRows}
            integrations={integrations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            hiddenColumns={['id', 'actions', 'schedule_date']}
            loading={loading}
          />
        </Box>
      ) : selectedDatagrid === 'Products Inventory' ? (
        <>
          <Box sx={{
            mx: '0.5rem',
            mt: '0.5rem',
            height: '50%',
            width: '100%'
          }}>
            <InventoryMainDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          </Box>
          <Box sx={{
            m: '0.5rem',
            height: '45%',
            width: '100%'
          }}>
            <ShipmentsDatagrid
              rows={shipmentRows}
              setRows={setShipmentRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          </Box>
        </>
      ) : selectedDatagrid === 'Materials Inventory' ? (
        <>
          <Box sx={{
            mx: '0.5rem',
            mt: '0.5rem',
            height: '50%',
            width: '100%'
          }}>
            <InventoryMainDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              type="Material"
              loading={loading}
            />
          </Box>
          <Box sx={{
            m: '0.5rem',
            height: '45%',
            width: '100%'
          }}>
            <ShipmentsDatagrid
              rows={shipmentRows}
              setRows={setShipmentRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          </Box>
        </>
      ) : selectedDatagrid === 'Documents' ? (
        <>
          <Box sx={{
            m: '0.5rem',
            height: '100%',
            width: '100%'
          }}>
            <DocumentDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              setProductsRows={setProductsRows}
              setMaterialsRows={setMaterialsRows}
              integration={integrations.find(integration => integration.integration_type.category === 'HQ')}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              isDialogView={false}
              loading={loading}
            />
          </Box>
        </>
      ) : null
      }
    </>
  );
};

export default VisualizeTable;
