import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';

const Dropdown = ({ label, value, options, onChange, size = 'small', variant = 'outlined', sx = { my: '0.25rem' }, fullWidth = true }) => {
  return (
    <FormControl size={size} variant={variant} sx={sx} fullWidth={fullWidth}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`} 
        label={label}
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
