import * as React from 'react';
import MapIcon from '@mui/icons-material/Map';
import DatasetIcon from '@mui/icons-material/Dataset';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
  Typography,
  Button,
  Box
} from '@mui/material';

export default function ToggleMetricsView({ currentView, setCurrentView }) {

  const buttonStyles = {
    color: 'white',
    minWidth: '110px',
    backgroundColor: 'transparent',
    textTransform: 'none',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
  };

  return (
    <Box sx={{ ml: '0.75rem', display: 'flex' }}>
      <Button
        onClick={() => setCurrentView('map')}
        sx={buttonStyles}
        variant='text'
      >
        <MapIcon />
        <Typography variant="body2" sx={{ ml: 1 }}>Map</Typography>
      </Button>
      <Button
        onClick={() => setCurrentView('table')}
        sx={buttonStyles}
        variant='text'
      >
        <DatasetIcon />
        <Typography variant="body2" sx={{ ml: 1 }}>Table</Typography>
      </Button>
      <Button
        onClick={() => setCurrentView('dashboard')}
        sx={buttonStyles}
        variant='text'
      >
        <TrendingUpIcon />
        <Typography variant="body2" sx={{ ml: 1 }}>Trends</Typography>
      </Button>
    </Box>
  );
}
