
import { axiosInstance, checkToken } from './utils.js'

const fetchDocuments = async (integration_id = null, document_category = null) => {
  try {
    const token = await checkToken();
    const params = new URLSearchParams();
    if (integration_id) params.append('integration_id', integration_id);
    if (document_category) params.append('document_category', document_category);
    const queryString = params.toString();
    const url = `/documents/` + (queryString ? `?${queryString}` : '');
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const refreshDocuments = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/documents/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createDocument = async (integration_id, fileName, fileData, shipment_id = null) => {
  try {
    const token = await checkToken();
    const params = new URLSearchParams();
    if (shipment_id) params.append('shipment_id', shipment_id);
    const queryString = params.toString();
    const url = `/documents/upload/${integration_id}/` + (queryString ? `?${queryString}` : '');
    const response = await axiosInstance(token).post(url, { file_data: fileData, file_name: fileName });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchDocumentContent = async (id, name) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/documents_content/${id}/${encodeURIComponent(name)}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const analyzeDocument = async (data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/document_process/`, data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const analyzeBOL = async (data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/process_bol_document/`, data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateDocumentDetails = async (id, data) => {
  try {
    const token = await checkToken();
    const url = `/document_process/${id}`;
    const response = await axiosInstance(token).patch(url, data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateDocument = async (data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch('/document/', data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateInvoiceDetails = async (orderId, orderType, data) => {
  try {
    const token = await checkToken();
    const url = `/invoice/${orderId}/${orderType}/`;
    const response = await axiosInstance(token).patch(url, data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const deleteDocument = async (id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/documents/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchCertifications = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/certifications/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchEmailLogs = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/email_logs/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export {
  fetchDocuments,
  refreshDocuments,
  createDocument,
  fetchDocumentContent,
  analyzeDocument,
  analyzeBOL,
  updateDocument,
  updateDocumentDetails,
  updateInvoiceDetails,
  deleteDocument,
  fetchCertifications,
  fetchEmailLogs
};
